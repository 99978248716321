import React, { FC } from "react"
import Layout from "components/templates/Layout"
import DefiPortfolio from './BorrowPortfolio'

const DefiBorrow: FC = () => {

  return (
    <Layout>
      <DefiPortfolio />
      {/* <DefiTokenSummary
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders ?? {}}
      /> */}
      {/* <DefiLoanDetails /> */}
      {/* <DefiTransactions /> */}
    </Layout>
  )
}

export default DefiBorrow
