import  bigDecimal from 'js-big-decimal';
import { useState, useEffect } from 'react';
import { useGetAssetsQuery, AssetDto, useGetPricesQuery, PricesDto } from '../state/store/api';
import getUsDollar from './dollarFormat'

const useAssets = () => {
  const getAssetsQuery = useGetAssetsQuery();
  const getPricesQuery = useGetPricesQuery();
  const [assets, setAssets] = useState<AssetDto[]>([]);
  const [prices, setPrices] = useState<PricesDto[]>([]);

  useEffect(() => {
    const assets = getAssetsQuery.data?.assets;
    if (assets) {
      setAssets(assets);
    }
  }, [getAssetsQuery]);

  useEffect(() => {
    const prices = getPricesQuery.data?.prices;
    if (prices) {
      setPrices(prices);
    }
  }, [getAssetsQuery, getPricesQuery]);

  const getAsset = (name?: string) => {
    const results = assets.filter(a => a.name === name)
    results.sort((first, second) => (first?.identifier?.length ?? 0) - (second?.identifier?.length ?? 0))
    return results[0]
  }

  const getAssetByIdentifier = (name?: string) => {
    return assets.find(a => a.identifier?.toUpperCase() === name?.toUpperCase())
  }

  const getPriceFormatted = (asset: string = '', amount: string | number = 0) => {
    const assetDto = assets.find((a) => a.name?.toUpperCase() === asset.toUpperCase());
    const price = formatWithFractionDigits(amount.toString(), assetDto?.fractionDigits);
    const assetName = asset.toUpperCase();
    return `${price} ${assetName}`;
  };

  const getPriceFormattedI = (asset: string = '', amount: string | number = 0) => {
    if(asset === 'USD') {
      return `${getUsDollar(amount)}`
    }
    const assetDto = assets.find((a) => a.identifier?.toUpperCase() === asset.toUpperCase());

    if(assetDto) {
      const price = formatWithFractionDigits(amount.toString(), assetDto?.fractionDigits);
      const assetName = assetDto?.name ?? '';
      return `${price} ${assetName}`;
    } else {
      return `${amount} ${asset}`;
    }
  };

  const getPriceFormattedShort = (asset: string = '', amount: string | number = 0): string => {
    let shortAmount = amount;

    if(asset === 'USD') {
      return getUsDollar(amount);
    }

    let fractionalDigits = 8;

    const assetDto = assets.find((a) => a.identifier?.toUpperCase() === asset.toUpperCase());
    if(assetDto) {
      fractionalDigits = Math.min((assetDto?.fractionDigits || 8), fractionalDigits)
    }
    
    shortAmount = formatWithFractionDigits(shortAmount.toString(), fractionalDigits);
    return shortAmount;
  }

  const getPrice = (asset: string = '', amount: string | number = 0) => {
    const assetDto = assets.find((a) => a.name?.toUpperCase() === asset.toUpperCase());
    amount = new bigDecimal(amount).getValue();
    return formatWithFractionDigits(amount, assetDto?.fractionDigits);
  };

  const formatWithFractionDigits = (price: string, fractionDigits: number = 8) => {
    const dotIndex = price.indexOf('.');
    if (dotIndex === -1) {
      return price;
    }
    const integerPart = price.slice(0, dotIndex);
    const decimalPart = price.slice(dotIndex + 1);
    
    const trimmedDecimalPart = decimalPart.replace(/0+$/, '');
    if(trimmedDecimalPart.length === 0) {
      return integerPart;
    }
    const finalDecimalPart = trimmedDecimalPart.slice(0, fractionDigits);
    return `${integerPart}.${finalDecimalPart}`;

  }

  const getPriceI = (asset: string = '', amount: string | number = 0) => {
    const assetDto = assets.find((a) => a.identifier?.toUpperCase() === asset.toUpperCase());    
    return formatWithFractionDigits(amount.toString(), assetDto?.fractionDigits);
  };

  const getDollarPrice = (asset: string = '', amount: string | number = 0) => {
    try {
      const price = prices.find((p) => p.identifier?.toUpperCase() === asset.toUpperCase());
      if (price && price.price) {
        const total = new bigDecimal(+price?.price).multiply(new bigDecimal(+amount));
        return `$${total.round(2).getValue()}`;
      } 
    } catch(e){
      console.log("Error calculating dollar price for asset " + asset, e)
    } 
    return '-'
  };

  return {
    assets,
    assetsLoading: getAssetsQuery.isLoading,
    getPriceFormatted,
    getDollarPrice,
    getPrice,
    getPriceI,
    getAsset,
    getAssetByIdentifier,
    getPriceFormattedI,
    getPriceFormattedShort
  };
};

export default useAssets;
