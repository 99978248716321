import styled from 'styled-components';

export const InfoText = styled.p`
    font-size: 0.7rem;
    opacity: 0.8;
    font-weight: 500;
`;

export const Paragraph = styled.p`
    opacity: 0.9;
    font-size: 0.8rem;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
`;

export const ClickHere = styled.strong`
    cursor: pointer;
    color: #eb92ff;
`;
