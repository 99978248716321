import React from 'react'
import styled from 'styled-components'
import { CustodyMainText, CustodyStyledPrintableTable } from '../CustodyStyles'
import useCustodyStatement from './useCustodyStatement'
import { useParams } from 'react-router-dom'
import { StatementWithdraw } from 'state/store/statementsApi'
import useAssets from 'shared/useAssets'

const CustodyStatementCostBasisWithdrawals = () => {
  const { month, year } = useParams()
  const {realizedWithdrawals} = useCustodyStatement({month, year})

  return (
  <div>
    <TokenSummaryTitle>Cost Basis for Withdrawals</TokenSummaryTitle>
    <CustodyStyledPrintableTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Network</th>
          <th>Amount</th>
          <th>USD Value</th>
          <th>Total Network Fee</th>
          <th>Overall Cost Basis</th>
          <th>Transaction Id</th>
          <th>Acquisition Date</th>
        </tr>
      </thead>
      <tbody>
        {realizedWithdrawals?.data?.map((record, index) => <CustodySummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledPrintableTable>
  </div>
  )
}

interface CustodySummaryRowProps {
  record: StatementWithdraw
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  const {getPriceFormattedI} = useAssets()
  return (
    <tr>
      <td>
        <CustodyMainText>
        {record.closingDate}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {record.network}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getPriceFormattedI(record.amount.asset, record.amount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getPriceFormattedI(record.totalFees.asset, record.totalFees.amount)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getPriceFormattedI(record.realizedAmount.asset, record.realizedAmount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {record.abraTxUID}
        </CustodyMainText>
      </td>
      <td>
        <MockText>
        -
        </MockText>
      </td>
    </tr>
  )
}

const MockText = styled.span`
  color: #E99928;
` 
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`
export default CustodyStatementCostBasisWithdrawals
