import React, {FC, useEffect, useState} from 'react';
import {Heading, Text, Title} from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import styled from 'styled-components';
import Percent from 'components/atoms/Number/Percent';
import Currency from 'components/atoms/Number/Currency';
import {palette} from 'lib/theme';
import Row from 'components/atoms/Row/Row';
import {useGetAssetsQuery} from 'state/store/api';
import LoadingStage from 'pages/LoadingStage';
import {formatNumberDisplay} from 'shared';
import {pricesSelector} from 'state/slice/prices.slice';
import {useSelector} from 'react-redux';
import useBorrow from './useBorrow'
import Borrow from '/public/assets/png/Borrow_Private.png';
import DigitalAsset from '/public/assets/png/Digital_Asset_Private.png';
import Focus from '/public/assets/png/Focus_Private.png';
import Private from '/public/assets/png/Private_Private.png';
import Image from 'components/atoms/Image'

interface BorrowPortfolioProps {}

const BorrowPortfolio: FC<BorrowPortfolioProps> = () => {
  const { borrowBalances} = useBorrow();

  const [legends, setLegends] = useState<{title: string; detail: string}[]>([]);
  const [loans, setLoans] = useState<PieChartItem[]>([]);
  const [collaterals, setCollaterals] = useState<PieChartItem[]>([]);
  const [balance, setBalance] = useState<number>(0);

  const prices = useSelector(pricesSelector);
  const {data: assetsInfo, isLoading: assetsLoading} = useGetAssetsQuery();
  const cardData: CardData[] = [
    {
        icon: DigitalAsset,
        title: "Collateral LTV",
        description: "We calculate the BTC collateral needed based on your loan amount. (this is your loan-to-value ratio (LTV))."
    },
    {
        icon: Borrow,
        title: "Initiate Loan",
        description: "It takes ~2 business days to process the loan. Loan proceeds are available in either USDC or USD (via wire) to your bank account."
    },
    {
        icon: Focus,
        title: "Monitor Loan",
        description: "Abra tracks your loan's status and sends monthly statements showing interest, balance, and fees."
    },
    {
        icon: Private,
        title: "Loan Payoff",
        description: "Close your loan anytime. During the loan, you can repay it or liquidate collateral to cover the loan + interest."
    }
];
  useEffect(() => {
    if (!borrowBalances.data && !prices && !assetsInfo) return;
    const summary = borrowBalances.data

    const outstandingBalance = summary?.totalPrincipalValue?.amount ?? 0
    const collateral = summary?.totalCollateralValue?.amount
    const averageLTV = summary?.ltv
    const healthFactor = summary?.healthFactor

    const currentLegends = [
      {
        title: 'Collateral',
        detail: formatNumberDisplay(collateral, 'currency') ?? '-',
      },
      {
        title: 'Average LTV',
        detail: formatNumberDisplay(averageLTV, 'percent') ?? '-',
      },
      {
        title: 'Health Factor',
        detail: `${formatNumberDisplay(healthFactor, 'decimal')}` ?? '-',
      },
    ];

    setBalance(+outstandingBalance);
    setLegends(currentLegends);
    setLoans(loans);
    setCollaterals(collaterals);
  }, [borrowBalances.data, prices, assetsInfo]);

  return (
    <PanelWithHeader width='100%' minHeight='174px' title='Lending and collateral overview'>
      {(borrowBalances.isLoading || assetsLoading) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingStage loadingText=' ' />
        </div>
      )}
      {(!borrowBalances.isLoading && borrowBalances.data?.hasLoan 
        && <>
      <div>
        <Row direction='column' gap='5px'>
          <Title>Outstanding Balance</Title>
          <Heading variant='h1'>
            <Currency value={balance} />
          </Heading>
        </Row>
        <Row margin='24px 0px 43px 0px'>
          <Details legends={legends} />
        </Row>
      </div>
      
      <ContactButton>
          Contact{' '}
          <StyledLink
              href='mailto:support@abra.com'
              aria-label='Email support'
              >
              globalsales@abra.com
          </StyledLink>{' '}
          if you would like to make any changes to your loan
      </ContactButton>
                </>)}
      { !borrowBalances.isLoading && !borrowBalances.data?.hasLoan && <div>

      <Title>Loan with Abra</Title>
      <CryptoGrid>
          {cardData.map((card, index) => (
            <Card key={index} {...card} />
            ))}
      </CryptoGrid>
      <ContactButton>
          Contact{' '}
          <StyledLink
              href='mailto:support@abra.com'
              aria-label='Email support'
              >
              globalsales@abra.com
          </StyledLink>{' '}
          if you are interested in taking a loan
      </ContactButton>
      </div>
    }
    </PanelWithHeader>
  );
};

const Details: FC<{legends: {title: string; detail: string}[]}> = ({legends}) => {
  return (
    <WrapRow>
      {legends.map((m) => {
        return <Detail key={m.title} {...m} />;
      })}
    </WrapRow>
  );
};

const WrapRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
`;

const Detail: FC<{title: string; detail: string}> = ({title, detail}) => {
  return (
    <Row direction='column' gap='8px'>
      <Title>{title}</Title>
      <Text size='large' letterSpacing='-0.48px'>
        {detail}
      </Text>
    </Row>
  );
};

const Card: React.FC<CardData> = React.memo(({ icon, title, description }) => (
  <CardContainer>
      <CardHeader>
          <Image width={42} height={42} src={icon} alt={`${title} icon`} />
          <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CryptoCard>
          <p>{description}</p>
      </CryptoCard>
  </CardContainer>
));
interface PieChartItem {
  name: string;
  amount: number;
  currencyAmount: number;
  percent: number;
}
interface PieChartItemsProps {
  title?: string;
  reverseColors?: boolean;
  list: PieChartItem[];
}
const PieChartItems: FC<PieChartItemsProps> = ({title, reverseColors = false, list}) => {
  const values = list.map((item) => {
    return {value: item.currencyAmount};
  });

  const colors = reverseColors ? (palette.charts.balancePieChart as string[]).reverse() : (palette.charts.balancePieChart as string[]);

  return (
    <Row justifyContent='flex-start' alignItems='center' gap='16px'>
      <div style={{position: 'relative', width: '220px', height: '220px'}}>
        <div
          style={{
            top: '50%',
            left: ' 50%',
            transform: 'translateY(-50%) translateX(-50%)',
            margin: '0',
            padding: '10px 0',
            position: 'absolute',
          }}
        >
          <Text size='large' bold>
            {title}
          </Text>
        </div>
      </div>
      <Row direction='column' justifyContent='center' width='262.5px' height='282px' gap='11px'>
        {list.length ? (
          list.map(({name, amount, currencyAmount, percent}, index: number) => (
            <Row gap='12px' key={`${index}-coin`}>
              <Row gap='0.50rem' alignItems='center' width='250px' height='18px'>
                <Dot color={colors[index]} />
                <Text ellipsis size='small'>
                  {` ${name}`}{' '}
                </Text>
                <Text size='small' color={palette.gray.main as string}>
                  {amount}
                </Text>{' '}
              </Row>
              <Row gap='0.25rem' alignItems='center'>
                <Text size='small'>
                  <Currency value={currencyAmount} />
                </Text>
                <Text size='tiny' color={palette.darkBackgroundContrast.light70}>
                  (
                  <Percent signDisplay='auto' maximumFractionDigits={0} value={percent} />)
                </Text>
              </Row>
            </Row>
          ))
        ) : (
          <></>
        )}
      </Row>
    </Row>
  );
};

const Dot = styled.div<{color?: string}>`
  background-color: ${({color}) => color || 'none'};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex: 0 0 auto;
  transition: 1s all;
`;

const ContactButton = styled.div`
    margin-top: 25px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: block; 
    margin-bottom: 2rem;
    font-size: 1.2rem;
    width: 100%; 
    box-sizing: border-box; 
    text-align: center;
    max-width: 560px;
`;
const StyledLink = styled.a`
    color: ${props => props.theme.colors.text};
    font-weight: bold;
    text-decoration: underline;
`;
const CryptoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: stretch;
    width: 100%;
`;

const CryptoCard = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
`;
const ModalLink = styled.a`
  color: ${palette.accent.light};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.51px;
`;



const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 50px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 1rem;
    width: 100%; 
    max-width: 300px;
    margin: 0 auto;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1200px; 
    margin: 0 auto; 
`;

const CardTitle = styled.h2`
    font-size: 1.85rem;
    font-weight: 500;
    margin: 0;
`;






interface CardData {
  icon: string;
  title: string;
  description: string;
}
export default BorrowPortfolio;
