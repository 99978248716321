import { Copy } from 'assets/icons';
import Image from 'components/atoms/Image';
import { toast, ToastType } from 'components/organisms/Toast';
import React from 'react';
import { AssetDto } from 'state/store/api';
import { CustodyBalance, CustodyNetworkMap } from 'state/store/custodyApi';
import styled from 'styled-components';
import { CustodyMainText, CustodyStyledPrintableTable, CustodyStyledTable } from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import { useParams } from 'react-router-dom';
import { RealizedGainLossResponse } from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import InkindAssetRenderer from 'components/atoms/asset/InkindAssetRenderer';

const CustodyStatementGainLossDetails = () => {
  const { month, year } = useParams();
  const { realizedGainLoss } = useCustodyStatement({ month, year });
  const gainLoss = realizedGainLoss?.data ?? [];
  return (
    <div>
      <TokenSummaryTitle>Gain / Loss Details</TokenSummaryTitle>
      <CustodyStyledPrintableTable>
        <thead>
          <tr>
            <th>Date</th>
            <th>Strategy</th>
            <th>Action</th>
            <th>Transaction Type</th>
            <th>Closing Amount</th>
            <th>Closing Quantity</th>
            <th>Closing Price</th>
            <th>Cost Basis</th>
            <th>Transaction Id</th>
            <th>Duration</th>
            <th>Realized Gain / Loss**</th>
          </tr>
        </thead>
        <tbody>
          {gainLoss.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
          ))}
        </tbody>
      </CustodyStyledPrintableTable>
    </div>
  );
};

interface CustodySummaryRowProps {
  record: RealizedGainLossResponse;
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({ record }) => {
  const { getPriceFormattedI } = useAssets();
  const strategyName = record.strategyName ?? '-';
  const actionName = record.actionName ?? '-';
  const transactionCategory = record.transactionCategory ?? '-';
  return (
    <tr>
      <td>
        <CustodyMainText>
          {/* Date */}
          {record.closingDate}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{strategyName}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{actionName}</CustodyMainText>
      </td>
      <td>
      <CustodyMainText>{transactionCategory}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {/* Amount */}
          {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <InkindAssetRenderer balance={record.amount} />
      </td>
      <td>
        <InkindAssetRenderer balance={{amount: record.closingPrice.rate, asset: record.closingPrice.quoteCurrency}} />
      </td>
      <td>
        <InkindAssetRenderer balance={record.totalCostBasis} />
      </td>
      <td>
        <CustodyMainText>
          {/* Transaction Id */}
          {record.abraTxUID}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {/* Duration */}
          {record.duration}
        </CustodyMainText>
      </td>
      <td>
        <InkindAssetRenderer balance={record.realizedAmount} />
      </td>
    </tr>
  );
};

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;
export default CustodyStatementGainLossDetails;
