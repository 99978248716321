import React from 'react';
import { TransferAddress } from 'state/store/withdrawApi';
import styled from 'styled-components';
import { Paragraph, Container } from './styles';

interface AddressStatusInfoProps {
    address: TransferAddress | undefined;
    navigateToAddresses: () => void;
}

const AddressStatusInfo: React.FC<AddressStatusInfoProps> = ({
    address,
    navigateToAddresses,
}) => {
    if (!address) return null;

    return (
        <Container>
            {address.usedWithdrawCompleted && address.verified && (
                <Paragraph>
                    This address has been verified and used before to
                    successfully transfer assets.
                </Paragraph>
            )}
            {address.usedWithdrawCompleted && !address.verified && (
                <Paragraph>
                    This address has been used before to transfer. For your
                    security, please confirm if the funds were received.
                </Paragraph>
            )}
            {!address.usedWithdrawCompleted && address.usedWithdrawRequest && address.usedWithdrawError && (
                <Paragraph>
                    This transaction failed on the network. Please verify the
                    address and either retry the transaction or delete the
                    address.
                </Paragraph>
            )}
            {!address.usedWithdrawCompleted && !address.usedWithdrawRequest && (
                <Paragraph>
                    This address has never been used before. You are first
                    required to perform a small test transaction to validate the
                    address.
                </Paragraph>
            )}
            <Paragraph>
                If you prefer to transfer to a different address, please
                <ClickHere onClick={navigateToAddresses}>
                    {' '}
                    click here{' '}
                </ClickHere>
                to register.
            </Paragraph>
        </Container>
    );
};

const ClickHere = styled.span`
    cursor: pointer;
    color: #eb92ff;
`;

export default AddressStatusInfo;
