import React, { FC } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import useAssets from 'shared/useAssets';

interface Props {
  quantity: string | number;
  asset?: string;
}
const TokenQuantity: FC<Props> = ({ quantity = '', asset = '' }) => {
  const {getPriceFormattedShort, getPriceFormattedI, getAssetByIdentifier} = useAssets();

  const shortAmount = getPriceFormattedShort(asset, quantity)
  const fullAmount = getPriceFormattedI(asset, quantity)
  const humanReadableBalanceAsset = getAssetByIdentifier(asset);
  
  return (
    <Tooltip text={fullAmount}>
      <FlexContainer>
        <TotalTokenQuantity>{shortAmount}</TotalTokenQuantity>
        <span>{humanReadableBalanceAsset?.name}</span>
      </FlexContainer>
    </Tooltip>
  )
};

const FlexContainer = styled.div`
  display: flex;
  cursor: help;
  align-items: center;
  gap: 0.5ch;
`;
const TotalTokenQuantity = styled.span`
  display: inline-block;
  max-width: 12ch;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default TokenQuantity;
