import { LeftArrow } from 'assets/icons';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import DigitVerification from 'components/atoms/DigitVerification';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import RadioButton from 'components/atoms/RadioButton';
import Tooltip from 'components/atoms/Tooltip';
import { Heading } from 'components/atoms/Typography';
import Modal from 'components/organisms/Modal';
import { toast, ToastType } from 'components/organisms/Toast';
import Layout from 'components/templates/Layout';
import Widget from 'components/templates/Widget';
import { TypesNamesEnum } from 'enums/Button.enum';
import { PagesUrl } from 'lib/constants/config.constant';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import NetworkCard from 'pages/WhitelistedAddresses/NetworkCard';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAssets from 'shared/useAssets';
import usePermissions from 'shared/usePermissions';
import PrimeTerms from 'assets/abra-prime.docx';
import { setNetworksLoading } from 'state/slice/networksReducer';
import {
  AccessTokenWrapper,
  BoostNetwork,
  TransferAddress,
  useCreateWithdrawRequestMutation,
  useGetDailyLimitQuery,
  useGetNetworksQuery,
  useGetTermsServiceCheckedQuery,
  useGetWhitelistAddressesQuery,
  useSetCheckTermsServiceMutation,
  useSendEmailVerificationMutation,
  useWithdrawRequestEmailValidationMutation,
  useWithdrawRequestOtpValidationMutation,
  useWithdrawRequestPasswordValidationMutation,
  WithdrawableOrder,
  WithdrawRequest,
} from 'state/store/withdrawApi';
import styled from 'styled-components';
import ConfirmValidAddress from './ConfirmValidAddress';
import useWithdrawableOrders from './useWithdrawableOrders';
import useNetworks from 'pages/WhitelistedAddresses/useNetworks'

interface WithdrawFromOrderProps {
  order: WithdrawableOrder;
}

const WithdrawFromOrder: FC<WithdrawFromOrderProps> = ({ order }) => {
  const getAddresses = useGetWhitelistAddressesQuery(null, { refetchOnMountOrArgChange: true });
  const limit = useGetDailyLimitQuery(order.currency.toUpperCase());
  const [minError, setMinError] = useState(false);
  const [insufficient, setInsufficient] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [startWithdrawModal, setStartWithdrawModal] = useState(false);
  const [withdrawRequest, setWithdrawRequest] = useState<WithdrawRequest>();
  const [addresses, setAddresses] = useState<TransferAddress[]>([]);
  const [address, setAddress] = useState<TransferAddress>();
  const [network, setNetwork] = useState<BoostNetwork>();
  const [password, setPassword] = useState('');
  const [otpCode, setOtpCode] = useState<string[]>([]);
  const [emailCode, setEmailCode] = useState<string[]>([]);
  const assets = useAssets();
  const getNetworks = useGetNetworksQuery(order.currency, { skip: !order.currency });
  const navigate = useNavigate();
  const [createWithdrawRequest, createWithdrawRequestInfo] = useCreateWithdrawRequestMutation();
  const [passwordValidation, passwordValidationInfo] = useWithdrawRequestPasswordValidationMutation();
  const [sendEmailValidationCode, sendEmailValidationCodeInfo] = useSendEmailVerificationMutation();
  const [emailValidation, emailValidationInfo] = useWithdrawRequestEmailValidationMutation();
  const [otpValidation, otpValidationInfo] = useWithdrawRequestOtpValidationMutation();
  const [loading, setLoading] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState<'terms' | 'password' | 'otp' | 'email' | 'confirm'>('terms');
  const [amount, setAmount] = useState<string>('0');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { username } = usePermissions();
  const [validationToken, setValidationToken] = useState<AccessTokenWrapper>();
  const dispatch = useDispatch();
  const [fee, setFee] = useState<'deducted' | 'added'>('deducted');
  const getTerms = useGetTermsServiceCheckedQuery()
  const [checkTerms] = useSetCheckTermsServiceMutation()

  const networksHook = useNetworks({boost: true, custodyDeposit: false, custodyWithdraw: false})

  const networks = networksHook.networks

  const setFirstStep = () => {
    if(termsAccepted) setWithdrawStep('password')
    else setWithdrawStep('terms')
  }

  const startWithdrawRequest = async () => {
    const withdraw: WithdrawRequest = { amount: generateAmountDeducted().toString(), orderId: order.id1Token, whitelistAddressId: address?.id || 0 };
    const withdrawRequest = await createWithdrawRequest(withdraw).unwrap();
    setWithdrawRequest(withdrawRequest); 
  }

  useEffect(() => {
    if(withdrawStep === 'password' && startWithdrawModal) {
      startWithdrawRequest()
    }
  }, [withdrawStep, startWithdrawModal])

  useEffect(() => {
    if(getTerms.currentData) {
      var data = getTerms.currentData
      var checked = data.checked
      setTermsAccepted(checked)
      if(checked && !startWithdrawModal) {
        setWithdrawStep('password')
      }
    }
  }, [getTerms])

  const clickTermsAccepted = async () => {
    setTermsAccepted(!termsAccepted)
    await checkTerms(!termsAccepted)
    getTerms.refetch()
  }

  const navigateToAddresses = () => {
    navigate(`${PagesUrl.ADDRESSES}/${order.currency.toUpperCase()}/${order.id}`);
  };

  const generateMinimalAmount = () => {
    let minimalAmount = network?.withdrawalMin ? +network.withdrawalMin : 0;
    if(fee === 'deducted') {
      minimalAmount += (network?.withdrawalFee ? +network.withdrawalFee : 0)
    }
    return assets.getPrice(order.currency.toUpperCase(), minimalAmount);
  };

  const generateMaximumAmount = () => {
    if (address && network) {
      if (!address.verified) {
        let maximumAmount = +(network?.withdrawalMin!) * 5;
        return assets.getPrice(order.currency, maximumAmount);
      } else {
        let maximumAmount = Math.min(network?.withdrawalMax!, +order.balance);
        if (limit.data && !Number.isNaN(limit.data)) {
          maximumAmount = Math.min(maximumAmount, parseFloat(limit.data));
        }
        return assets.getPrice(order.currency, maximumAmount);
      }
    }
    return 0;
  };

  useEffect(() => {
    const minimalAmount = generateMinimalAmount();
    const maximumAmount = generateMaximumAmount();
    if (Number.isNaN(amount)) {
      setAmount(minimalAmount.toString());
    } else if (+amount < minimalAmount) {
      setAmount(minimalAmount.toString());
    } else if (+amount > maximumAmount) {
      setAmount(maximumAmount.toString());
    }
  }, [network]);

  useEffect(() => {
    setLoading(createWithdrawRequestInfo.isLoading || passwordValidationInfo.isLoading || sendEmailValidationCodeInfo.isLoading || emailValidationInfo.isLoading || otpValidationInfo.isLoading);
  }, [createWithdrawRequestInfo, passwordValidationInfo, sendEmailValidationCodeInfo, emailValidationInfo, otpValidationInfo]);

  const markAsVerified = (address: TransferAddress) => {
    setAddress({ ...address, verified: true });
    const updatedAddresses = addresses.map((a) => (a.id === address.id ? { ...a, verified: true } : a));
    setAddresses(updatedAddresses);
  };

  useEffect(() => {
    const listener = captureEnterEvent as unknown as EventListener;
    document.addEventListener('keydown', listener);
    return function cleanup() {
      document.removeEventListener('keydown', listener);
    };
  }, [withdrawStep, termsAccepted, password, otpCode, emailCode]);

  const captureEnterEvent = (e: KeyboardEvent<HTMLElement>) => {
    const enterIsPressed = e.key === 'Enter';
    const valid = currentStepIsValid();
    if (enterIsPressed && valid) {
      proceedWithdraw();
    }
  };

  const stepsModalHeader = () => {
    if (withdrawStep === 'terms') return 'Terms of Service';
    if (withdrawStep === 'password') return 'Step 1 of 3 - Password Validation';
    if (withdrawStep === 'otp') return 'Step 2 of 3 - Authenticator Code Validation';
    if (withdrawStep === 'email') return 'Step 3 of 3 - Email Code';
    if (withdrawStep === 'confirm') return 'Confirmation';
    return 'Withdraw Request';
  };

  useEffect(() => {
    if (amount === '-') {
      setMinError(false);
      return;
    }
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMinError(true);
      return;
    }
    if (!network) {
      return;
    }

    const minimalAmount = +generateMinimalAmount();
    if (+amount < minimalAmount) {
      setMinError(true);
    } else {
      setMinError(false);
    }
  }, [amount, network, fee]);

  useEffect(() => {
    if(network && order) {
      setInsufficient(+order.balance < +network?.withdrawalMin!)
    } else {
      setInsufficient(false)
    }
  }, [network, amount])

  useEffect(() => {
    //max validation
    if (amount === '-') return;
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMaxError(true);
      return;
    }
    if (!network) {
      setMaxError(true);
      return;
    }
    if (!address) {
      setMaxError(true);
    }
    const amountDeducted = generateAmountDeducted();
    const maximumAmount = +generateMaximumAmount();
    if (amountDeducted > maximumAmount) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
  }, [amount, network, fee]);

  useEffect(() => {
    if (amount === '-') return;
    if (!Number.isNaN(amount)) {
      const parsedAmount = +amount;
      const steps = network?.withdrawalIntegerMultiple!.split('.');
      if (steps && steps[1]) {
        const amountSteps = amount.toString().split('.');
        if (amountSteps && amountSteps[1]) {
          const size = steps[1].length;
          const amountSize = amountSteps[1].length;
          if (amountSize > size) {
            setAmount(parsedAmount.toFixed(size));
          }
        }
      }
    }
  }, [amount, network]);

  useEffect(() => {
    dispatch(setNetworksLoading(getNetworks.isLoading || getNetworks.isFetching));
  }, [getNetworks]);

  useEffect(() => {
    if (getAddresses.currentData) {
      setAddresses(getAddresses.currentData.whitelist ?? []);
    } else setAddresses([]);
  }, [getAddresses]);

  useEffect(() => {
    const network = networks.find((network) => address?.network === network.network);
    setNetwork(network);
  }, [address, networks]);

  const registeredAddressesForThatNetwork = addresses
    .filter((address) => networks.find((network) => address.network === network.network))
    .filter((address) => address.asset === order.currency.toUpperCase());

  const toggleSelectedAddress = (transferAddress: TransferAddress) => {
    address ? setAddress(undefined) : setAddress(transferAddress);
  };

  const startWithdraw = () => {
    setStartWithdrawModal(true);
  };

  const currentStepIsValid = () => {
    if (withdrawStep === 'terms') {
      return termsAccepted;
    }
    if (withdrawStep === 'password') {
      return password;
    }
    if (withdrawStep === 'otp') {
      return otpCode.length === 6;
    }
    if (withdrawStep === 'email') {
      return emailCode.length === 8;
    }
    return true;
  };

  const proceedWithdraw = async () => {
    if (withdrawStep === 'terms') {
      const withdraw: WithdrawRequest = { amount: generateAmountDeducted().toString(), orderId: order.id1Token, whitelistAddressId: address?.id || 0 };
      const withdrawRequest = await createWithdrawRequest(withdraw).unwrap();
      setWithdrawRequest(withdrawRequest);
      setWithdrawStep('password');
    }
    if (withdrawStep === 'password') {
      const tokenPromise = passwordValidation({ password, username }).unwrap();
      const emailCodePromise = sendEmailValidationCode(withdrawRequest?.id?.toString() || '').unwrap();
      const [ token, _ ] = await Promise.all([tokenPromise, emailCodePromise]);
      setValidationToken(token);
      setWithdrawStep('otp');
    }
    if (withdrawStep === 'otp') {
      const validation = { mfaToken: validationToken?.accessToken ?? '', otpCode: otpCode.join('') };
      await otpValidation({ validation, withdrawRequestId: withdrawRequest?.id ?? 0 }).unwrap();
      setWithdrawStep('email');
    }
    if (withdrawStep === 'email') {
      setWithdrawStep('confirm');
    }
    if (withdrawStep === 'confirm') {
      try {
        await emailValidation({ code: emailCode.join(''), withdrawRequestId: withdrawRequest?.id ?? 0 }).unwrap();
        toast.show({
          type: ToastType.Success,
          title: 'Your withdraw request was submitted',
          content: 'Please wait while the network processes your request, you will receive a confirmation email once the process has been completed',
          duration: 15000,
        });
        navigate(PagesUrl.BOOST);
      } catch (e) {
        setWithdrawStep('email');
      }
    }
  };

  const cancelWithdraw = () => {
    setStartWithdrawModal(false);
    setFirstStep()
    setPassword('');
    setOtpCode([]);
    setEmailCode([]);
  };

  const keyDownOtp = (event: { keyCode: number }, indx: number) => {
    if (event.keyCode === 8) {
      if (otpCode[indx]) {
        const updatedCureentValue = otpCode.slice(0, indx);
        setOtpCode(updatedCureentValue);
        return;
      }
      const updatedValue = otpCode.slice(0, indx - 1);
      setOtpCode(updatedValue);
    }
  };

  const changeOtp = (event: any) => {
    setOtpCode([...otpCode, event?.target?.value]);
  };

  const keyDownEmail = (event: { keyCode: number }, indx: number) => {
    if (event.keyCode === 8) {
      if (emailCode[indx]) {
        const updatedCureentValue = emailCode.slice(0, indx);
        setEmailCode(updatedCureentValue);
        return;
      }
      const updatedValue = emailCode.slice(0, indx - 1);
      setOtpCode(updatedValue);
    }
  };

  const changeEmail = (event: any) => {
    setEmailCode([...emailCode, event?.target?.value]);
  };

  const pasteEmailCode = async () => {
    const text = await navigator.clipboard.readText();
    if (text.trim().length === 8) {
      setEmailCode(text.trim().split(''));
    }
  };

  const n = (text: string | undefined) => {
    if (Number.isNaN(text)) return 0;
    else return parseFloat(text ?? '0');
  };

  const generateAmountSent = () => {
    if (fee === 'added') {
      return n(amount);
    } else {
      return n(amount) - n(network?.withdrawalFee);
    }
  };

  const generateAmountDeducted = () => {
    if (fee === 'added') {
      return n(amount) + n(network?.withdrawalFee);
    } else {
      return n(amount);
    }
  };

  const checkPendingAddressHasError = (address?: TransferAddress) => {
    if (address) {
      const isPending = !address.usedWithdrawCompleted && address.usedWithdrawRequest;
      const hasError = address.usedWithdrawError;
      return isPending && hasError;
    }
    return false;
  };
  const verifiedAddresses = registeredAddressesForThatNetwork.filter((a) => a.verified);
  const pendingConfirmFundAddresses = registeredAddressesForThatNetwork.filter((a) => !a.verified && a.usedWithdrawCompleted);
  const pendingAddresses = registeredAddressesForThatNetwork.filter((a) => !a.usedWithdrawCompleted && a.usedWithdrawRequest && !a.usedWithdrawError);
  const newAddresses = registeredAddressesForThatNetwork.filter((a) => !a.usedWithdrawRequest || (a.usedWithdrawRequest && a.usedWithdrawError));
  // addresses that where used for withdraw but where not successfull and have errors

  return (
    <WithdrawBox>
      <Heading variant='h3'>Withdraw from deposit #{order.id1Token}</Heading>

      <Container>
        <Paragraph>
          Current deposit balance is <strong>{assets.getPriceFormatted(order.currency, order.balance)}</strong>
        </Paragraph>
        {!!limit && !!limit.data && (
          <Paragraph>
            Withdraw daily limit accross deposits is <strong>{assets.getPriceFormatted(order.currency, limit.data)}</strong>
          </Paragraph>
        )}
      </Container>

      {!getNetworks.isLoading && registeredAddressesForThatNetwork.length === 0 && (
        <Container>
          <Paragraph>
            You do not have a registered address to transfer {order.currency.toUpperCase()}. Please
            <ClickHere onClick={navigateToAddresses}> Click here </ClickHere>
            to register a new {order.currency.toUpperCase()} address.
          </Paragraph>
        </Container>
      )}
      {!getNetworks.isLoading && registeredAddressesForThatNetwork.length > 0 && (
        <>
          {!address && (
            <Container>
              <Paragraph>
                Please select the address you would like to withdraw your <strong>{order.currency.toUpperCase()}</strong> assets to.
              </Paragraph>
              <Paragraph>
                If you prefer to transfer to a different address, <ClickHere onClick={navigateToAddresses}>click here </ClickHere>
                to register a new {order.currency.toUpperCase()} address.
              </Paragraph>
            </Container>
          )}
          {address && (
            <Container>
              {address.usedWithdrawCompleted && address.verified && (
                <Paragraph>This address has been verified and used before to successfully transfer assets.</Paragraph>
              )}
              {address.usedWithdrawCompleted && !address.verified && (
                <Paragraph>This address has been used before to transfer, for your security, confirm if the funds were received.</Paragraph>
              )}
              {!address.usedWithdrawCompleted && address.usedWithdrawRequest && (
                <Paragraph>This transaction failed on the network. Please verify the address and either retry the transaction or delete the address.</Paragraph>
              )}
              {!address.usedWithdrawCompleted && !address.usedWithdrawRequest && (
                <Paragraph>
                  This address has never been used before, you are first required to perform a small test transaction to validate the address.
                </Paragraph>
              )}
              <Paragraph>
                If you prefer to transfer to a different address, <ClickHere onClick={navigateToAddresses}>Click here </ClickHere>
                to register a new {order.currency.toUpperCase()} address.
              </Paragraph>
            </Container>
          )}
          <WithdrawAddressAndAmountBox>
            <AddressesBox collapsed={!!address}>
              {verifiedAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Verified Address{verifiedAddresses.length > 1 ? 'es' : ''}</h5>}
                    {verifiedAddresses.map((a) => (
                      <EmbeddedAddressBox key={a.id} address={a} selectedAddress={address} toggleSelectedAddress={toggleSelectedAddress} />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingConfirmFundAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Please Verify</h5>}
                    {pendingConfirmFundAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        markAsVerified={markAsVerified}
                        notSelectable
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Verification in Progress</h5>}
                    {pendingAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        notSelectable
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {newAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Unverified Address{newAddresses.length > 1 ? 'es' : ''}</h5>}
                    {newAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {network && address && (
                <>
                  <NetworkCard max={generateMaximumAmount()} min={generateMinimalAmount()} network={{...network, isBoost: true, isCustody: false}}></NetworkCard>
                </>
              )}
            </AddressesBox>
            {network && (
              <InputActionsBox>
                <LabelInputBox>
                  {!address?.verified && (
                    <InfoText>
                      For security measures, you may only withdraw up to
                      <strong> {assets.getPriceFormatted(order.currency, generateMaximumAmount())}</strong> until you have confirmed the receipt of test funds.
                    </InfoText>
                  )}
                  <ConfirmValidAddress address={address} markAsVerified={markAsVerified} />
                  <LabelAmount htmlFor='inputAmount'>Select the Amount of {order.currency.toUpperCase()} to withdraw</LabelAmount>
                  <InputAmount
                    id='inputAmount'
                    placeholder='Amount'
                    type='number'
                    step={network.withdrawalIntegerMultiple}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </LabelInputBox>
                <Radiobox>
                  <p>Deduct the network fee of {network.withdrawalFee} from the requested amount.</p>
                  <RadioGroup>
                    <RadioButton name='Fee' text='Yes' value='deducted' checked={fee === 'deducted'} onClick={() => setFee('deducted')} />
                    <RadioButton name='Fee' text='No' value='added' checked={fee === 'added'} onClick={() => setFee('added')} />
                  </RadioGroup>
                  <p>
                    {assets.getPriceFormatted(order.currency, generateAmountDeducted())} will be deducted from your deposit and{' '}
                    {assets.getPriceFormatted(order.currency, generateAmountSent())} will be sent to the selected address.{' '}
                  </p>
                </Radiobox>
                <Button
                  height='44'
                  disabled={minError || insufficient || maxError || (address && !address?.verified && address.usedWithdrawRequest)}
                  error={minError || maxError}
                  buttonType={TypesNamesEnum.ACCENT}
                  label='Next'
                  onClick={startWithdraw}
                />
                <ErrorMessage>
                  {address && !address?.verified && address.usedWithdrawRequest && (
                    <p>Unable to withdraw to this address until you have confirmed the receipt of test funds</p>
                  )}
                  {minError && <p>Can't withdraw less than the minimal amount of {generateMinimalAmount()}</p>}
                  {insufficient && <p>Insufficient funds</p>}
                  {maxError && <p>Exceeds maximum amount of {generateMaximumAmount()}</p>}
                </ErrorMessage>
              </InputActionsBox>
            )}
          </WithdrawAddressAndAmountBox>
        </>
      )}
      <Modal visible={startWithdrawModal} header={stepsModalHeader()}>
        <Modal.Body>
          {withdrawStep === 'terms' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please confirm the withdrawal address, otherwise you may lose your funds.</ParagraphModal>
                <ParagraphModal>
                  In addition, you are required to click on the following link to review the 
                  <ExternalLink href={PrimeTerms} download='Abra Prime User Agreement' target='_blank'>
                    {' '}
                    Abra Prime User Agreement{' '}
                  </ExternalLink>{' '}
                  and “Accept Terms” to continue.
                </ParagraphModal>

                <Checkbox text='Accept Terms' checked={termsAccepted} onChange={() => clickTermsAccepted()} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'password' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>To proceed with your withdraw request, please confirm your password.</ParagraphModal>
                <br />
                <Input
                  label='Your password here'
                  inputProps={{
                    value: password,
                    type: 'password',
                    onChange: (e: any) => {
                      setPassword(e.target.value);
                    },
                  }}
                />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'otp' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please type the code provided from your authenticator app. This is the same application you used to login.</ParagraphModal>
                <DigitVerification code={otpCode} onChange={changeOtp} onKeyDown={keyDownOtp} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'email' && (
            <>
              <NormalSpacingBox onPaste={pasteEmailCode}>
                <ParagraphModal>Please check your email, you should have received a six-digit verification code from Abra.</ParagraphModal>
                <DigitVerification code={emailCode} onChange={changeEmail} onKeyDown={keyDownEmail} digitsLength={8}/>
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'confirm' && (
            <>
              <ParagraphModalHeader>
                The amount of{' '}
                <strong>
                  {generateAmountDeducted()} {order.currency.toUpperCase()}
                </strong>{' '}
                will be deducted from your deposit balance of <strong>{assets.getPriceFormatted(order.currency, order.balance)}.</strong>
              </ParagraphModalHeader>
              <ParagraphSent>Transaction Preview</ParagraphSent>
              <SpacingBox>
                <LongParagraph>
                  <strong>Network:</strong> {network?.name}
                </LongParagraph>
                <LongParagraph>
                  <strong>Address:</strong> {address?.address}
                </LongParagraph>
                {address?.memo && (
                  <LongParagraph>
                    <strong>Destination Tag / MEMO:</strong> {address.memo}
                  </LongParagraph>
                )}
                <LongParagraph>
                  <strong>Amount To Be Sent: </strong> {assets.getPriceFormatted(order.currency, generateAmountSent())}
                </LongParagraph>
                <LongParagraph>
                  <strong>Network Fee:</strong> {network?.withdrawalFee} {order.currency.toUpperCase()}
                </LongParagraph>
              </SpacingBox>
              <br></br>
              <ParagraphModal>Once you confirm, we will submit the transaction to the network.</ParagraphModal>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button height='44' buttonType={TypesNamesEnum.SECONDAY} label='Cancel' onClick={cancelWithdraw} />
          <Button
            height='44'
            buttonType={TypesNamesEnum.ACCENT}
            label={withdrawStep === 'confirm' ? 'Confirm' : 'Next'}
            onClick={proceedWithdraw}
            disabled={!currentStepIsValid()}
            isLoading={loading}
          />
        </Modal.Footer>
      </Modal>
    </WithdrawBox>
  );
};

const Withdraw = () => {
  const { orderId = '0' } = useParams();
  const { withdrawableOrders } = useWithdrawableOrders();
  const selectedOrder = withdrawableOrders.find((order) => order.id === +orderId);

  return (
    <Layout customGrid={alternativeGridSystem}>
      <Widget>{selectedOrder && <WithdrawFromOrder order={selectedOrder} />}</Widget>
    </Layout>
  );
};

interface GroupboxAddressProps {
  show: boolean;
}
const GroupboxAddress = styled.div<GroupboxAddressProps>`
  border: ${({ show }) => (show ? '1px solid rgba(255, 255, 255, 0.1)' : '0px solid black')};
  padding: ${({ show }) => (show ? '4px 8px' : '0')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
`;
interface TextInputProps {
  invalid?: boolean;
}

const AddressBoxInfo = styled.div`
  padding: 1vh 1vw 1vh 1vh;
`;
const RadioGroup = styled.div`
  display: flex;
  gap: 2vh;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
`;
const Radiobox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  margin-top: 1vh;
`;
const AddressesBoxContainer = styled.div`
  display: flex;
`;
interface BackButtonProps {
  show: boolean;
}
const BackButton = styled.div<BackButtonProps>`
  transition: all 0.3s ease-in;
  width: ${(props) => (props.show ? '14px' : '0px')};
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
const ErrorMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #ec396b;
  line-height: 0.7rem;
  padding: 0;
  margin: 0;
`;
const InfoText = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  font-weight: 500;
`;
const NormalSpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;
const SpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;
const ParagraphModalHeader = styled.p`
  font-size: 0.8rem;
  margin-top: 3vh;
  opacity: 0.9;
`;
const ParagraphModal = styled.p`
  font-size: 0.8rem;
  opacity: 0.9;
`;
const LongParagraph = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  word-break: break-all;
`;
const LabelInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 2vh;
`;

const LabelAmount = styled.label`
  font-size: 0.7rem;
  opacity: 0.9;
`;
const InputActionsBox = styled.div`
  background-color: rgba(163, 153, 246, 0.1);
  padding: 1vw 1vw;
  border-radius: 0px 0px 12px 12px;
  flex-grow: 1;
  justify-content: space-between;
  gap: 1vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
`;

const WithdrawAddressAndAmountBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
interface AddressesBoxProps {
  collapsed: boolean;
}
const AddressesBox = styled.div<AddressesBoxProps>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  background-color: rgba(163, 153, 246, 0.1);
  padding: 1vw 1vw;
  margin-top: 2vh;
  gap: ${({ collapsed }) => (collapsed ? '0' : '1vh')};
  border-radius: ${({ collapsed }) => (collapsed ? '12px 12px 0px 0px' : '12px')};
  transition: all 0.4s ease-in;
`;

interface AddressBoxProps {
  visible: boolean;
  selected: boolean;
  address: TransferAddress;
  notSelectable?: boolean;
}

const AddressBox = styled.div<AddressBoxProps>`
  background-color: ${({ address }) => (address?.verified ? '#17B96BBB' : 'rgba(255, 255, 255, 0.2)')};
  position: relative;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  padding: 0;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
  border-radius: 12px;
  overflow: hidden;
  word-break: break-all;
  font-size: 0.7rem;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
  cursor: ${({ notSelectable }) => (notSelectable ? 'not-allowed' : 'pointer')};
  opacity: 1;
  ${({ selected, visible, address }) => {
    if (selected) {
      return 'background-color:' + address?.verified ? '#17B96BDD' : 'rgba(255, 255, 255, 0.4);';
    }
    if (!visible) {
      return 'height: 0; overflow: hidden; padding: 0; opacity: 0;';
    }
  }}
  ${({ notSelectable, address }) =>
    notSelectable
      ? ''
      : `
  &:hover {
    background-color: ${address?.verified ? '#17B96BFF' : 'rgba(255, 255, 255, 0.4)'};
  }
    `};
`;

const ParagraphSent = styled.p`
  opacity: 0.9;
  font-size: 0.8rem;
  margin-top: 3vh;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  opacity: 0.9;
  font-size: 0.8rem;
`;

const InputAmount = styled.input<TextInputProps>`
  background-color: transparent;
  color: white;
  border: 0;
  border-bottom:${({ invalid }) => (!invalid ? '1px solid rgba(255,255,255,0.8)' : '2px solid rgba(255,20,20,0.8)')};
  width: 28ch;
  font-size: 0.7rem;
  flex-grow: 1;
  max-height: 22px;
  max-width: 100%;
  display: flex;
`;

const ClickHere = styled.strong`
  cursor: pointer;
  color: #eb92ff;
`;

const WithdrawBox = styled.div``;

export default Withdraw;

interface AddressBoxForWithdrawProps {
  address: TransferAddress;
  toggleSelectedAddress: (a: TransferAddress) => void;
  markAsVerified?: (a: TransferAddress) => void;
  selectedAddress?: TransferAddress;
  notSelectable?: boolean;
  hasError?: boolean;
}
const EmbeddedAddressBox: FC<AddressBoxForWithdrawProps> = (props) => {
  const { address, toggleSelectedAddress, selectedAddress, markAsVerified, notSelectable, hasError } = props;
  return (
    <>
      <AddressBox
        notSelectable={!(!notSelectable || hasError)}
        address={address}
        visible={!selectedAddress}
        selected={address?.id === selectedAddress?.id}
        key={address.id}
        onClick={() => (!notSelectable || hasError) && toggleSelectedAddress(address)}
      >
        <AddressesBoxContainer>
          <BackButton show={address.id === selectedAddress?.id}>
            <LeftArrow />
          </BackButton>
          <AddressBoxInfo>
            <div>
              <div>
                <strong>Asset: </strong> {address.asset ?? ' --- '}
              </div>
              <div>
                <strong>Network: </strong>
                {address.networkName}
              </div>
              <div>
                <strong>Address: </strong>
                {address.address}
              </div>
              {address.memo && (
                <div>
                  <strong>Memo: </strong>
                  {address.memo}
                </div>
              )}
            </div>
            {address.notes && (
              <div>
                <strong>Notes: </strong>
                {address.notes}
              </div>
            )}
          </AddressBoxInfo>
        </AddressesBoxContainer>
        <TooltipBox>
          {markAsVerified && <ConfirmValidAddress address={address} button={true} markAsVerified={markAsVerified} />}
          {hasError && (
            <Tooltip text='There was an error during the process on the network. You can try to do another transaction or delete this address.' autoDismiss>
              <Icon name='Info' color='red' size={20} />
            </Tooltip>
          )}
          {!hasError && notSelectable && (
            <Tooltip text='You are unable to withdraw to this address until you have confirmed the receipt of test funds.' autoDismiss>
              <Icon name='Info' color='orange' size={20} />
            </Tooltip>
          )}
        </TooltipBox>
      </AddressBox>
    </>
  );
};

const TooltipBox = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const ExternalLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;
