import React from 'react';
import Modal from 'components/organisms/Modal';
import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';

interface DeleteAddressModalProps {
    visible: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    isLoading: boolean;
}

const DeleteAddressModal: React.FC<DeleteAddressModalProps> = ({
    visible,
    onCancel,
    onConfirm,
    isLoading,
}) => {
    return (
        <Modal visible={visible}>
            <Modal.Body>
                <h3>Are you sure you want to delete the transfer address?</h3>
                <br />
                <p>If needed you can always add it back in the future</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    buttonType={TypesNamesEnum.SECONDAY}
                    label='Cancel'
                    height='40'
                    onClick={onCancel}
                    disabled={isLoading}
                />
                <Button
                    buttonType={TypesNamesEnum.ACCENT}
                    label='Confirm'
                    height='40'
                    onClick={onConfirm}
                    isLoading={isLoading}
                    disabled={isLoading}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteAddressModal;
