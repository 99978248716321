import { LeftArrow } from 'assets/icons';
import Icon from 'components/atoms/Icon';
import bigDecimal from 'js-big-decimal';
import Tooltip from 'components/atoms/Tooltip';
import { toast, ToastType } from 'components/organisms/Toast';
import Layout from 'components/templates/Layout';
import { PagesUrl } from 'lib/constants/config.constant';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAssets from 'shared/useAssets';
import usePermissions from 'shared/usePermissions';
import {
  AccessTokenWrapper,
  TransferAddress,
  useDeleteAddressMutation,
  useGetTermsServiceCheckedQuery,
  useGetWhitelistAddressesQuery,
  useSetCheckTermsServiceMutation,
  WithdrawRequest,
} from 'state/store/withdrawApi';
import styled from 'styled-components';
import useCustodyWithdraw from './extraCustodyHooks/useCustodyWithdraw';
import ConfirmValidAddress from 'pages/Withdraw/ConfirmValidAddress';
import {
  useCreateCustodyWithdrawRequestMutation,
  useSendCustodyUsernameAndPasswordMutation,
  useValidateCustodyEmailCodeMutation,
  useValidateCustodyOTPCodeMutation,
  useSendEmailVerificationMutation,
} from 'state/store/custodyApi';
import { selectCurrentUser } from 'state/slice/auth.slice';
import CustodyNetworkCard from './CustodyNetworkCard';
import CustodyNavigationWidget from './CustodyNavigationWidget';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import { CustodyAssetComponentContainer } from './CustodyAssetComponent';
import { MarginContainer } from './Custody';
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect';
import { AssetDto } from 'state/store/api';
import CustodyAssetBalanceComponent from './CustodyAssetBalanceComponent'
import TransactionSidebar from 'pages/TransactionScreen/TransactionSidebar'
import useWithdrawRequests from 'pages/WhitelistedAddresses/useWithdrawRequest'
import { capitalize } from 'shared'
import AccountInfo from './withdrawal/WithdrawAccountInfo';
import WithdrawForm from './withdrawal/WithdrawForm';
import { categorizeAddresses, num } from './withdrawal/helper';
import { WarningAmount } from './withdrawal/types';
import { ClickHere, Container, Paragraph } from './withdrawal/styles';
import AddressStatusInfo from './withdrawal/WithdrawAddressStatusInfo';
import DeleteAddressModal from './withdrawal/DeleteAddressModal';
import WithdrawModal from './withdrawal/WithdrawalModal';

interface WithdrawFromAssetProps {
  asset: string;
}

const WithdrawFromAsset: FC<WithdrawFromAssetProps> = ({ asset }) => {
  const { setSelectedAsset, selectedAsset, selectedNetwork, selectedFeeAsset, custodyAssetNetworkInformation, loadingNetworks, loadingWallet, maxUnverifiedWithdraw, minWithdraw } =
    useCustodyWithdraw();

  useEffect(() => {
    setSelectedAsset(asset);
  }, [asset, setSelectedAsset]);

  const getAddresses = useGetWhitelistAddressesQuery(null, { refetchOnMountOrArgChange: true });
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [startWithdrawModal, setStartWithdrawModal] = useState(false);
  const [withdrawRequest, setWithdrawRequest] = useState<WithdrawRequest>();
  const [addresses, setAddresses] = useState<TransferAddress[]>([]);
  const [address, setAddress] = useState<TransferAddress>();
  const [password, setPassword] = useState('');
  const [otpCode, setOtpCode] = useState<string[]>([]);
  const [emailCode, setEmailCode] = useState<string[]>([]);
  const assets = useAssets();
  const navigate = useNavigate();
  const [showDeleteAddress, setShowDeleteAddress] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(0);
  const [deleteAddress, deleteAddressStatus] = useDeleteAddressMutation();
  const startDeleteAddress = (address: TransferAddress) => {
    setAddressToDelete(address.id ?? 0);
    setShowDeleteAddress(true);
  };

  const [createWithdrawRequest, createWithdrawRequestInfo] = useCreateCustodyWithdrawRequestMutation();
  const [passwordValidation, passwordValidationInfo] = useSendCustodyUsernameAndPasswordMutation();
  const [sendEmailValidationCode, sendEmailValidationCodeInfo] = useSendEmailVerificationMutation();
  const [emailValidation, emailValidationInfo] = useValidateCustodyEmailCodeMutation();
  const [otpValidation, otpValidationInfo] = useValidateCustodyOTPCodeMutation();

  const [loading, setLoading] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState<'terms' | 'password' | 'otp' | 'email' | 'confirm'>('terms');
  const [amount, setAmount] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { username } = usePermissions();
  const [validationToken, setValidationToken] = useState<AccessTokenWrapper>();
  const [feeSameAsAsset, setFeeSameAsAsset] = useState(false);
  const getTerms = useGetTermsServiceCheckedQuery();
  const [checkTerms] = useSetCheckTermsServiceMutation();
  const [warningAmount, setWarningAmount] = useState<WarningAmount>({
    amountNotEnough: false,
    amountExactlyTheSame: false,
    estimatedFeeSurpassAvailable: false,
  });


  useEffect(() => {
    if (amount === '') {
      return;
    }
    const feeAsset = custodyAssetNetworkInformation?.availableAmountInFeeAsset?.asset ?? '';
    const availableFee = num(custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? '0');
    const estimatedFee = num(custodyAssetNetworkInformation?.estimatedFee?.amount ?? '0');
    const availableAmountInAsset = num(custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? '0');
    const asset = custodyAssetNetworkInformation?.availableAmountInAsset?.asset ?? '';
    const amountSelected = num(amount ?? 0);
    let amountNotEnough = false;
    let estimatedFeeSurpassAvailable = estimatedFee.compareTo(availableFee) > 0; // if the estimated fee is more than the available fee
    let amountExactlyTheSame = false
    if (feeAsset === asset) {
      // if the available amount is less than the amount selected plus the estimated fee
      amountNotEnough = availableAmountInAsset.compareTo(amountSelected.add(estimatedFee)) < 0; 
      // if the amount selected is exactly the same as the maximum withdrawal
      amountExactlyTheSame = +amount > 0 && (num(amount).compareTo(num(getMaximumWithdrawalAmount())) === 0)
    }
    setWarningAmount({ amountNotEnough, estimatedFeeSurpassAvailable, amountExactlyTheSame });
  }, [amount, address, selectedFeeAsset, custodyAssetNetworkInformation]);

  useEffect(() => {
    const sameAsset = custodyAssetNetworkInformation?.availableAmountInAsset.asset === custodyAssetNetworkInformation?.availableAmountInFeeAsset.asset;
    setFeeSameAsAsset(sameAsset);
  }, [custodyAssetNetworkInformation]);

  const setFirstStep = () => {
    if (termsAccepted) setWithdrawStep('password');
    else setWithdrawStep('terms');
  };

  const startWithdrawRequest = async () => {
    const withdraw = {
      amount: generateAmountDeducted(),
      whitelistAddressId: address?.id || 0,
      currency: asset,
      network: selectedNetwork?.network ?? '',
    };
    const withdrawRequest = await createWithdrawRequest(withdraw).unwrap();
    setWithdrawRequest(withdrawRequest);
  };

  useEffect(() => {
    if (withdrawStep === 'password' && startWithdrawModal) {
      startWithdrawRequest();
    }
  }, [withdrawStep, startWithdrawModal]);

  useEffect(() => {
    if (getTerms.currentData) {
      var data = getTerms.currentData;
      var checked = data.checked;
      setTermsAccepted(checked);
      if (checked && !startWithdrawModal) {
        setWithdrawStep('password');
      }
    }
  }, [getTerms]);

  const clickTermsAccepted = async () => {
    setTermsAccepted(!termsAccepted);
    await checkTerms(!termsAccepted);
    await getTerms.refetch();
  };

  const navigateToAddresses = () => {
    navigate(`${PagesUrl.ADDRESSES}`);
  };

  const generateMaximumAmount: () => bigDecimal = () => {
    if (address) {
      if (!address.verified) {
        const maxUnverifiedAmountNumber = num(maxUnverifiedWithdraw?.amount ?? '0');
        const maxInAsset = num(custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? '0');
        return maxUnverifiedAmountNumber.compareTo(maxInAsset) < 0 ? maxUnverifiedAmountNumber : maxInAsset;
      }
    }
    return num(custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? '0');
  };

  useEffect(() => {
    const minimalAmount = num(minWithdraw?.amount ?? '0');
    const maximumAmount = generateMaximumAmount();
    if (Number.isNaN(amount)) {
      setAmount(minimalAmount.getValue());
    } else if (num(amount).compareTo(minimalAmount) < 0) { // if amount is less than minimal amount
      setAmount(minimalAmount.getValue());
    } else if (num(amount).compareTo(maximumAmount) > 0) { // if amount is more than maximum amount
      setAmount(maximumAmount.getValue());
    }
  }, [selectedNetwork]);

  useEffect(() => {
    setLoading(
        createWithdrawRequestInfo.isLoading ||
        passwordValidationInfo.isLoading ||
        sendEmailValidationCodeInfo.isLoading ||
        emailValidationInfo.isLoading ||
        otpValidationInfo.isLoading
    );
  }, [createWithdrawRequestInfo, passwordValidationInfo, sendEmailValidationCodeInfo, emailValidationInfo, otpValidationInfo]);

  const markAsVerified = (address: TransferAddress) => {
    setAddress({ ...address, verified: true });
    const updatedAddresses = addresses.map((a) => (a.id === address.id ? { ...a, verified: true } : a));
    setAddresses(updatedAddresses);
  };

  useEffect(() => {
    const listener = captureEnterEvent as unknown as EventListener;
    document.addEventListener('keydown', listener);
    return function cleanup() {
      document.removeEventListener('keydown', listener);
    };
  }, [withdrawStep, termsAccepted, password, otpCode, emailCode]);

  const captureEnterEvent = (e: KeyboardEvent<HTMLElement>) => {
    const enterIsPressed = e.key === 'Enter';
    const valid = currentStepIsValid();
    if (enterIsPressed && valid) {
      proceedWithdraw();
    }
  };

  const stepsModalHeader = () => {
    if (withdrawStep === 'terms') return 'Terms of Service';
    if (withdrawStep === 'password') return 'Step 1 of 3 - Password Validation';
    if (withdrawStep === 'otp') return 'Step 2 of 3 - Authenticator Code Validation';
    if (withdrawStep === 'email') return 'Step 3 of 3 - Email Code';
    if (withdrawStep === 'confirm') return 'Confirmation';
    return 'Withdraw Request';
  };

  useEffect(() => {
    if (amount === '') {
      setMinError(false);
      return;
    }
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMinError(true);
      return;
    }
    if (!selectedNetwork) {
      return;
    }
    
    if (num(amount).compareTo(num(minWithdraw?.amount ?? '0')) < 0){ // if amount is less than minimal amount
      setMinError(true);
    } else {
      setMinError(false);
    }
  }, [amount, selectedNetwork, loadingWallet, address]);

  useEffect(() => {
    if (amount === '') return;
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMaxError(true);
      return;
    }
    if (!selectedNetwork) {
      setMaxError(true);
      return;
    }
    if (!address) {
      setMaxError(true);
    }
    const amountDeducted = generateAmountDeducted();
    const maximumAmount = generateMaximumAmount();
    if (num(amountDeducted).compareTo(maximumAmount) > 0) { // if amount is more than maximum amount
      setMaxError(true);
    } else {
      setMaxError(false);
    }
  }, [amount, address, loadingWallet, selectedNetwork]);

  useEffect(() => {
    if (amount === '') return;
    if (!Number.isNaN(amount)) {
      const parsedAmount = +amount;
      const steps = selectedAsset?.fractionDigits?.toString().split('.');
      if (steps && steps[1]) {
        const amountSteps = amount.toString().split('.');
        if (amountSteps && amountSteps[1]) {
          const size = steps[1].length;
          const amountSize = amountSteps[1].length;
          if (amountSize > size) {
            setAmount(parsedAmount.toFixed(size));
          }
        }
      }
    }
  }, [amount, address, loadingWallet, selectedAsset]);

  useEffect(() => {
    if (getAddresses.currentData) {
      setAddresses(getAddresses.currentData.whitelist ?? []);
    } else setAddresses([]);
  }, [getAddresses]);

  const registeredAddressesForThatNetwork = addresses
    .filter((address) => selectedNetwork?.network === address.network)
    .filter((address) => address.asset === selectedAsset?.name);

  const toggleSelectedAddress = (transferAddress: TransferAddress) => {
    address ? setAddress(undefined) : setAddress(transferAddress);
  };

  const startWithdraw = () => {
    setStartWithdrawModal(true);
  };

  const currentStepIsValid = () => {
    if (withdrawStep === 'terms') {
      return termsAccepted;
    }
    if (withdrawStep === 'password') {
      return password;
    }
    if (withdrawStep === 'otp') {
      return otpCode.length === 6;
    }
    if (withdrawStep === 'email') {
      return emailCode.length === 8;
    }
    return true;
  };

  const proceedWithdraw = async () => {
    if (withdrawStep === 'terms') {
      await checkTerms(true);
      await getTerms.refetch();
      setWithdrawStep('password');
    }
    if (withdrawStep === 'password') {
      const token = await passwordValidation({ password, username }).unwrap();
      setValidationToken(token);  
      await sendEmailValidationCode(withdrawRequest?.id?.toString() || '').unwrap();
      setWithdrawStep('otp');
    }
    if (withdrawStep === 'otp') {
      const validation = { mfaToken: validationToken?.accessToken ?? '', otpCode: otpCode.join('') };
      await otpValidation({ withdrawRequestId: withdrawRequest?.id ?? 0, ...validation }).unwrap();
      setWithdrawStep('email');
    }
    if (withdrawStep === 'email') {
      setWithdrawStep('confirm');
    }
    if (withdrawStep === 'confirm') {
      try {
        await emailValidation({ code: emailCode.join(''), withdrawRequestId: withdrawRequest?.id ?? 0 }).unwrap();
        toast.show({
          type: ToastType.Success,
          title: 'Your withdraw request was submitted',
          content: 'Please wait while the network processes your request, you will receive a confirmation email once the process has been completed',
          duration: 15000,
        });
        navigate(PagesUrl.CUSTODY);
      } catch (e) {
        setWithdrawStep('email');
      }
    }
  };

  const cancelWithdraw = () => {
    setStartWithdrawModal(false);
    setFirstStep();
    setPassword('');
    setOtpCode([]);
    setEmailCode([]);
  };

  const changeOtp = (event: any) => {
    setOtpCode([...otpCode, event?.target?.value]);
  };

  const changeEmail = (event: any) => {
    setEmailCode([...emailCode, event?.target?.value]);
  };

  const pasteEmailCode = async () => {
    const text = await navigator.clipboard.readText();
    if (text.trim().length === 8) {
      setEmailCode(text.trim().split(''));
    }
  };

  const generateAmountSent = () => {
    return num(amount).getValue();
  };

  const generateAmountDeducted = () => {
    return num(amount).getValue();
  };

  const displayBalancesToDeduct = () => {
    if (feeSameAsAsset) {
      let amountInKind = custodyAssetNetworkInformation?.availableAmountInAsset;
      return (
        <>
          <strong>{assets.getPriceFormattedI(amountInKind?.asset, amountInKind?.amount)}</strong>
        </>
      );
    } else {
      let amountInKind = custodyAssetNetworkInformation?.availableAmountInAsset;
      let amountInFee = custodyAssetNetworkInformation?.availableAmountInFeeAsset;
      return (
        <>
          <strong>
            {assets.getPriceFormattedI(amountInKind?.asset, amountInKind?.amount)} and {assets.getPriceFormattedI(amountInFee?.asset, amountInFee?.amount)}
          </strong>
        </>
      );
    }
  };

  const proceedDeleteAddress = async () => {
    await deleteAddress(addressToDelete);
    await getAddresses.refetch();
    setShowDeleteAddress(false);
    toast.show({
      type: ToastType.Success,
      title: 'Success',
      content: 'Address deleted successfully',
    });
  };

  const checkPendingAddressHasError = (address?: TransferAddress) => {
    if (address) {
      return address.usedWithdrawError;
    }
    return false;
  };

  const calculateAmountStep = (selectedAsset: AssetDto): string => {
    return new bigDecimal(Math.pow(10, -(selectedAsset?.fractionDigits ?? 0))).getValue();
  };

  const user = useSelector(selectCurrentUser);
 
  // Usage
  const addressCategories = categorizeAddresses(
    registeredAddressesForThatNetwork
  );

  const {
    verified: verifiedAddresses,
    pendingConfirmFund: pendingConfirmFundAddresses,
    pending: pendingAddresses,
    error: newAddresses,
  } = addressCategories;
  
  const availableAmountValue = custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? '0';
  const totalAmountValue = custodyAssetNetworkInformation?.totalAmountInAsset?.amount ?? '0';

  // if the available amount is different from the total amount
  const availableAmountIsDifferent = num(availableAmountValue).compareTo(num(totalAmountValue)) !== 0;

  const getMaximumWithdrawalAmount = () => {
    const availableAmount = generateMaximumAmount();
    if (!feeSameAsAsset) {
      return availableAmount.getValue();
    }
    const estimatedFee = custodyAssetNetworkInformation?.estimatedFee?.amount ?? '0';
    const value = availableAmount.subtract(num(estimatedFee));
    if (value.compareTo(num('0')) < 0) {
      return '0';
    }
    return value.getValue();
  }

  const handleDeleteConfirm = () => {
    proceedDeleteAddress();
    setShowDeleteAddress(false);
  };

  return (
    <WithdrawBox>
      <AccountInfo
        smaAccountNumber={user?.organization?.smaAccountNumber}
        loadingWallet={loadingWallet}
        custodyAssetNetworkInformation={custodyAssetNetworkInformation}
        asset={asset}
        availableAmountIsDifferent={availableAmountIsDifferent}
        feeSameAsAsset={feeSameAsAsset}
      />

      {loadingNetworks && <LoadingBar />}

      {!loadingNetworks && registeredAddressesForThatNetwork.length === 0 && (
        <Container>
          <Paragraph>
            You do not have a registered address to transfer {selectedAsset?.name}, please
            <ClickHere onClick={navigateToAddresses}> click here </ClickHere>
            to register.
          </Paragraph>
        </Container>
      )}
      {!loadingNetworks && registeredAddressesForThatNetwork.length > 0 && (
        <>
          {!address && (
            <Container>
              <Paragraph>
                Please select the address you would like to withdraw your <strong>{selectedAsset?.name}</strong> assets to.
              </Paragraph>
              <Paragraph>
                If you prefer to transfer to a different address, please <ClickHere onClick={navigateToAddresses}> click here </ClickHere>
                to register.
              </Paragraph>
            </Container>
          )}
          {address && (
            <AddressStatusInfo
              address={address} 
              navigateToAddresses={navigateToAddresses} 
            />
          )}
          <WithdrawAddressAndAmountBox>
            <AddressesBox collapsed={!!address}>
              {verifiedAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address} >
                    {!address && <h5>Verified Address{verifiedAddresses.length > 1 ? 'es' : ''}</h5>}
                    {verifiedAddresses.map((a) => (
                      <EmbeddedAddressBox key={a.id} address={a} selectedAddress={address} deleteAction={startDeleteAddress} toggleSelectedAddress={toggleSelectedAddress} />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingConfirmFundAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Please Verify</h5>}
                    {pendingConfirmFundAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        deleteAction={startDeleteAddress}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        markAsVerified={markAsVerified}
                        notSelectable
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Verification in Progress</h5>}
                    {pendingAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        deleteAction={startDeleteAddress}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        notSelectable
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {newAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Unverified Address{newAddresses.length > 1 ? 'es' : ''}</h5>}
                    {newAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        deleteAction={startDeleteAddress}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {selectedNetwork && address && custodyAssetNetworkInformation && (
                <>
                  <CustodyNetworkCard max={generateMaximumAmount().getValue()} network={selectedNetwork} info={custodyAssetNetworkInformation} min={minWithdraw?.amount ?? '0'}></CustodyNetworkCard>
                </>
              )}
            </AddressesBox>
            {selectedAsset && selectedNetwork && (
              <WithdrawForm
                selectedAsset={selectedAsset}
                selectedNetwork={selectedNetwork}
                address={address}
                amount={amount}
                setAmount={setAmount}
                minError={minError}
                maxError={maxError}
                warningAmount={warningAmount}
                generateMaximumAmount={generateMaximumAmount}
                calculateAmountStep={calculateAmountStep}
                getMaximumWithdrawalAmount={getMaximumWithdrawalAmount}
                startWithdraw={startWithdraw}
                markAsVerified={markAsVerified}
              />
            )}
          </WithdrawAddressAndAmountBox>
        </>
      )}
      <DeleteAddressModal 
        visible={showDeleteAddress}
        onCancel={() => setShowDeleteAddress(false)}
        onConfirm={handleDeleteConfirm}
        isLoading={deleteAddressStatus.isLoading}
      />
      <WithdrawModal
        visible={startWithdrawModal}
        withdrawStep={withdrawStep}
        termsAccepted={termsAccepted}
        password={password}
        otpCode={otpCode}
        emailCode={emailCode}
        selectedAsset={selectedAsset}
        selectedNetwork={selectedNetwork}
        address={address}
        assets={assets}
        custodyAssetNetworkInformation={custodyAssetNetworkInformation}
        loading={loading}
        onCancel={cancelWithdraw}
        onProceed={proceedWithdraw}
        onTermsAccept={clickTermsAccepted}
        onPasswordChange={setPassword}
        onOtpChange={changeOtp}
        onEmailChange={changeEmail}
        onEmailPaste={pasteEmailCode}
        generateAmountDeducted={generateAmountDeducted}
        displayBalancesToDeduct={displayBalancesToDeduct}
        generateAmountSent={generateAmountSent}
        currentStepIsValid={currentStepIsValid}
        stepsModalHeader={stepsModalHeader}
        setOtpCode={setOtpCode}
        setEmailCode={setEmailCode}
      />
    </WithdrawBox>
  );
};

const CustodyWithdraw = () => {
  const [selectedAsset, setSelectedAsset] = useState('');
  const { asset } = useParams();
  useCustodyRedirect();

  useEffect(() => {
    if (asset) {
      setSelectedAsset(asset);
    }
  }, [asset]);

  const { restart, balances } = useCustodyWithdraw();

  useEffect(() => {
    restart();
  }, []);

  const assetsHook = useAssets();
  const withdrawInfo = balances.data?.map(balance => {
    const asset = assetsHook.getAssetByIdentifier(balance.availableBalance?.asset)
    return {
      assetBalance: balance.availableBalance,
      dollarBalance: balance.availableValue,
      asset
    }
  })
  withdrawInfo?.sort((a, b) => +(b.dollarBalance?.amount ?? 0)  - +(a.dollarBalance?.amount ?? 0) );
  
  const canGoBack = selectedAsset;

  
  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        {selectedAsset && (
          <CustodyNavigationWidget action={canGoBack ? () => {
            setSelectedAsset('');
            restart();
          } : undefined} textAction='Change Asset'>
            {<WithdrawFromAsset asset={selectedAsset} />}
          </CustodyNavigationWidget>
        )}
        {!selectedAsset && (
          <CustodyNavigationWidget>
            <h1>Withdraw Crypto</h1>
            <WithdrawContainer>
              <CustodyAssetComponentContainer>
                <h3>Select the asset</h3>
                {withdrawInfo?.map((info) => (
                  <CustodyAssetBalanceComponent dollar={info.dollarBalance?.amount} balance={info.assetBalance?.amount} key={info.asset?.name} isClickable={true} asset={info.asset} onClick={() => setSelectedAsset(info.asset?.identifier ?? '')} />
                ))}
              </CustodyAssetComponentContainer>
            </WithdrawContainer>
          </CustodyNavigationWidget>
        )}
      </MarginContainer>
    </Layout>
  );
};

interface GroupboxAddressProps {
  show: boolean;
}

const GroupboxAddress = styled.div<GroupboxAddressProps>`
  border: ${({ show }) => (show ? '1px solid rgba(255, 255, 255, 0.1)' : '0px solid black')};
  padding: ${({ show }) => (show ? '4px 8px' : '0')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
`;

const AddressBoxInfo = styled.div`
  padding: 1vh 1vw 1vh 1vh;
`;

const AddressesBoxContainer = styled.div`
  display: flex;
`;

interface BackButtonProps {
  show: boolean;
}
const BackButton = styled.div<BackButtonProps>`
  transition: all 0.3s ease-in;
  width: ${(props) => (props.show ? '14px' : '0px')};
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const InfoText = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  font-weight: 500;
`;


const WithdrawAddressAndAmountBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
interface AddressesBoxProps {
  collapsed: boolean;
}

const AddressesBox = styled.div<AddressesBoxProps>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  background-color: rgba(163, 153, 246, 0.1);
  padding: 1vw 1vw;
  margin-top: 2vh;
  gap: ${({ collapsed }) => (collapsed ? '0' : '1vh')};
  border-radius: 12px 12px 0px 0px;
  transition: all 0.4s ease-in;
`;

interface AddressBoxProps {
  visible: boolean;
  selected: boolean;
  address: TransferAddress;
  notSelectable?: boolean;
}

const AddressBox = styled.div<AddressBoxProps>`
  background-color: ${({ address }) => (address?.verified ? '#17B96BBB' : 'rgba(255, 255, 255, 0.2)')};
  position: relative;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  padding: 0;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
  border-radius: 12px;
  overflow: hidden;
  word-break: break-all;
  font-size: 0.7rem;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
  cursor: ${({ notSelectable }) => (notSelectable ? 'not-allowed' : 'pointer')};
  opacity: 1;
  ${({ selected, visible, address }) => {
    if (selected) {
      return 'background-color:' + address?.verified ? '#17B96BDD' : 'rgba(255, 255, 255, 0.4);';
    }
    if (!visible) {
      return 'height: 0; overflow: hidden; padding: 0; opacity: 0;';
    }
  }}
  ${({ notSelectable, address }) =>
    notSelectable
      ? ''
      : `
  &:hover {
    background-color: ${address?.verified ? '#17B96BFF' : 'rgba(255, 255, 255, 0.4)'};
  }
    `};
`;

const WithdrawBox = styled.div``;

export default CustodyWithdraw;

interface AddressBoxForWithdrawProps {
  address: TransferAddress;
  toggleSelectedAddress: (a: TransferAddress) => void;
  markAsVerified?: (a: TransferAddress) => void;
  selectedAddress?: TransferAddress;
  notSelectable?: boolean;
  hasError?: boolean;
  deleteAction: (a: TransferAddress) => void;
}

const EmbeddedAddressBox: FC<AddressBoxForWithdrawProps> = (props) => {
  const { address, toggleSelectedAddress, selectedAddress, markAsVerified, notSelectable, hasError } = props;
  const [expanded, setExpanded] = useState(false)
  const withdrawRequests = useWithdrawRequests()
  const [transactionId, setTransactionId] = useState(0);
  const [show, setShow] = useState(false);

  const expand = (e: any) => {
    withdrawRequests.setAddressId(address.id ?? 0);
    setExpanded(!expanded)
    e.preventDefault()
    e.stopPropagation()
  }
  const loading = withdrawRequests.loading
  const requests = withdrawRequests.withdrawRequests;
  const sortedRequests = [...requests]
  sortedRequests.sort((r1, r2) => r1.transactionId && r2.transactionId ? r2.transactionId.toString().localeCompare(r1.transactionId.toString()) : 0)
  const select = (id: number) => {
    setShow(true);
    setTransactionId(id);
  };

  return (
    <>
      <AddressBox
        notSelectable={!(!notSelectable || hasError)}
        address={address}
        visible={!selectedAddress}
        selected={address?.id === selectedAddress?.id}
        key={address.id}
        onClick={() => (!notSelectable || hasError) && toggleSelectedAddress(address)}
      >
        <AddressesBoxContainer>
          <BackButton show={address.id === selectedAddress?.id}>
            <LeftArrow />
          </BackButton>
          <AddressBoxInfo>
            <div>
              <div>
                <strong>Asset: </strong> {address.asset ?? ' --- '}
              </div>
              <div>
                <strong>Network: </strong>
                {address.networkName}
              </div>
              <div>
                <strong>Address: </strong>
                {address.address}
              </div>
              {address.memo && (
                <div>
                  <strong>Memo: </strong>
                  {address.memo}
                </div>
              )}
            </div>
            {address.notes && (
              <div>
                <strong>Notes: </strong>
                {address.notes}
              </div>
            )}
          </AddressBoxInfo>
        </AddressesBoxContainer>
        <TooltipBox>
          <ActionArea>
            <ExpansionButton onClick={(e) => expand(e)}>{expanded ? <Icon name='UpArrowMid' /> : <Icon name='DownArrowMid' />}</ExpansionButton>
          </ActionArea>
          {markAsVerified && <ConfirmValidAddress address={address} button={true} markAsVerified={markAsVerified} />}
          {hasError && (
            <Tooltip text='There was an error during the process on the network. You can try to do another transaction or delete this address.' autoDismiss>
              <Icon name='Info' color='red' size={20} />
            </Tooltip>
          )}
          {!hasError && notSelectable && (
            <Tooltip text='You are unable to withdraw to this address until you have confirmed the receipt of test funds.' autoDismiss>
              <Icon name='Info' color='orange' size={20} />
            </Tooltip>
          )}
        </TooltipBox>
      <TransactionSidebar transactionId={transactionId} setTransactionId={setTransactionId} show={show} setShow={setShow} />
      </AddressBox>
      {expanded && (
        <WithdrawRequestsBox>
          {loading && <LoadingBar />}
          {!loading && requests.length === 0 && (
            <>
              <HeaderExpanded>
                {address.usedWithdrawRequest && !address.verified && <InfoText>There is a transaction currently being processed using this address, please wait.</InfoText>}
                {!address.usedWithdrawRequest && <InfoText>There are no records of transactions for this address</InfoText>}
                <DeleteHeader onClick={() => props.deleteAction(address)}>
                  <p>Delete Address</p>
                  <HeaderActionArea>
                    <ActionButton>
                      <Icon name='Trash' />
                    </ActionButton>
                  </HeaderActionArea>
                </DeleteHeader>
              </HeaderExpanded>
            </>
          )}
          {!loading && requests.length > 0 && (
            <>
              <HeaderExpanded>
                <h4>Transaction History</h4>
                <DeleteHeader onClick={() => props.deleteAction(address)}>
                  <p>Delete Address</p>
                  <HeaderActionArea>
                    <ActionButton>
                      <Icon name='Trash' />
                    </ActionButton>
                  </HeaderActionArea>
                </DeleteHeader>
              </HeaderExpanded>
              {sortedRequests.map((request) => (
                <WithdrawCards key={request.id}>
                  <WithdrawCard>
                    <p>
                      <>
                        {request.amount} {request.currency?.toUpperCase()}
                      </>
                      {request.status === 'SUCCESS' && request.transactionId && (
                        <>
                          {' '}
                          Withdraw - <TransactionLink onClick={() => select(request.transactionId ?? 0)}>Transaction #{request.transactionId}</TransactionLink>
                        </>
                      )}

                      {request.status === 'SUCCESS' && !request.transactionId && (
                        <>
                          {' '}
                          Withdraw - <TransactionNumber>Transaction {request.externalRequestUid}</TransactionNumber>
                        </>
                      )}
                    </p>
                    <StatusParagraph color={request.status === 'SUCCESS' ? 'green' : 'red'}>{capitalize(request.status ?? '')}</StatusParagraph>
                  </WithdrawCard>
                </WithdrawCards>
              ))}
            </>
          )}
        </WithdrawRequestsBox>
      )}
    </>
  );
};

const TooltipBox = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const WithdrawContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  gap: 2vh;
  margin-top: 3vh;
`;
const ActionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4vw;
  margin-right: 8px;
`;
const ExpansionButton = styled.div`
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease-in-out;
  border-radius: 50%;
  margin-left: 1vh;
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    font-size: 0.6rem;
    color: #ffffffcc;
  }
  &:hover {
    p {
      color: #ffffffaa;
    }
  }
`;
const HeaderExpanded = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
const TransactionLink = styled.span`
  cursor: pointer;
  color: #eb92ff;
  background-color: #ffffff00;
  transition: all 0.3s ease-in;
  border-radius: 1px;
  &:hover {
    background-color: #ffffff55;
  }
`;

const TransactionNumber = styled.span`
  color: #eb92ff;
  background-color: #ffffff00;
  border-radius: 1px;
`;

const StatusParagraph = styled.p`
  color: ${({ color }) => (color === 'green' ? '#17B96B' : '#EC396B')};
  font-size: 0.8rem;
  font-weight: bolder;
`;

const WithdrawCards = styled.div`
  display: flex;
  flex-direction: column;
`;

const WithdrawCard = styled.div`
  display: flex;
  gap: 1vw;
  margin-bottom: 1vh;
  border-radius: 6px;
  align-items: center;
  padding: 0.5vh 1vw;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;


const WithdrawRequestsBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 8px;
  padding: 4px 8px;
`;

const HeaderActionArea = styled.div`
  cursor: pointer;
  display: flex;
  gap: 3px;
`;
const ActionButton = styled.div`
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease-in-out;
  border-radius: 50%;
  margin-left: 1vh;
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;
