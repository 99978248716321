import React, { useState } from 'react'
import { RateOfReturn, useGetRateOfReturnsQuery } from 'state/store/custodyApi'
import { Chart } from "react-google-charts";
import styled from 'styled-components'
import Modal from 'components/organisms/Modal'
import { TypesNamesEnum } from 'enums/Button.enum'

interface Props {
  strategyIdentifier: string
}
const CustodyRateOfReturns: React.FC<Props> = ({strategyIdentifier}) => {
  const rateOfReturns = useGetRateOfReturnsQuery(strategyIdentifier)
  const [visible, setVisible] = useState(false)
  const returns = [...rateOfReturns?.data ?? []]
  
  const getMonthName = (yearMonth: string) => {
    const month = yearMonth.split('-')[1]
    const date = new Date(2024, +month - 1);
    return date.toLocaleString('default', { month: 'short' });
  }
  
  const format = (rateOfReturn: RateOfReturn) => {
    const month = getMonthName(rateOfReturn.month)
    const rate = +rateOfReturn.rate*100
    return {
      month, rate
    }
  }

  const lastReturn = returns.length > 0 ? returns[returns.length - 1] : null
  const lastReturnRate = lastReturn ? (+lastReturn.rate * 100).toFixed(2) : null
  returns?.sort((a,b) => a.month.localeCompare(b.month))
  const result = returns.map(format).map(r => [r.month, r.rate, `${r.rate.toFixed(2)}%`])
  return <ParentContainer onClick={() => setVisible(!visible)}>
    {lastReturnRate != null && 
      <Button>{lastReturnRate}%</Button>
    }
    <Modal visible={visible} onClose={() => setVisible(false)} header='Rate of Return'>
      <Modal.Body>
        <Container hidden={!visible}>
        <Chart
          className='chart'
          chartType="LineChart"
          data={[
            [{type: "string", label: "Month"}, {type: "number", label:"Rate"}, { type: 'string', 'role': 'tooltip', 'p': {'html': true}}]
          , ...result]} 
          width="100%"
          height="400px"
          options={{
            legend: 'none',
            colors: ['#6F2ACD'],
            backgroundColor: 'transparent',
            hAxis: {
              title: 'Month',
              textStyle: {
                color: 'white'
              },
              titleTextStyle: {
                color: 'white'
              }
            },
            vAxis: {
              title: 'Rate (%)',
              textStyle: {
                color: 'white'
              },
              titleTextStyle: {
                color: 'white'
              }
            }
          }}
        />
        </Container>
      </Modal.Body>
    </Modal>
  </ParentContainer>
}

const Button = styled.button`
  background-color: #6f2acdcc;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  margin-left: 2px;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 2px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`

const ParentContainer = styled.div`
`
const Container = styled.div`
  padding: 8px;
  width: 500px;
  height: 500px;
  border-radius: 8px;
  position: relative;
`
export default CustodyRateOfReturns
