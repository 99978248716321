import React from 'react';
import Tooltip from 'components/atoms/Tooltip';
import { Info } from 'assets/icons';
import { AssetNetworkInformation } from 'state/store/custodyApi';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Typography';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import useAssets from 'shared/useAssets';

interface AccountInfoProps {
    smaAccountNumber?: string;
    loadingWallet: boolean;
    custodyAssetNetworkInformation: AssetNetworkInformation | undefined;
    asset: string;
    availableAmountIsDifferent: boolean;
    feeSameAsAsset: boolean;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
    smaAccountNumber,
    loadingWallet,
    custodyAssetNetworkInformation,
    asset,
    availableAmountIsDifferent,
    feeSameAsAsset,
}) => {
    const assets = useAssets();
    return (
        <>
            <Heading variant='h3'>
                Withdraw{' '}
                {smaAccountNumber && `from SMA Account ${smaAccountNumber}`}
            </Heading>
            <Container>
                {loadingWallet && <LoadingBar />}
                {custodyAssetNetworkInformation && (
                    <>
                        <Paragraph>
                            Current account balance is{' '}
                            <strong>
                                {assets.getPriceFormattedI(
                                    asset,
                                    custodyAssetNetworkInformation
                                        ?.totalAmountInAsset?.amount
                                )}
                            </strong>
                        </Paragraph>
                        {availableAmountIsDifferent && (
                            <Paragraph>
                                Available amount for withdrawal is
                                <Tooltip text='Your balance available for withdrawal may be lower than the actual account balance because of estimated network fee as well as management fee and gas fee reserve requirements'>
                                    <strong>
                                        {' '}
                                        {assets.getPriceFormattedI(
                                            asset,
                                            custodyAssetNetworkInformation
                                                ?.availableAmountInAsset?.amount
                                        )}{' '}
                                        <Info color='#fffc' size={12} />
                                    </strong>
                                </Tooltip>
                            </Paragraph>
                        )}
                        {!feeSameAsAsset && (
                            <Paragraph>
                                This withdraw has an estimated fee of{' '}
                                {assets.getPriceFormattedI(
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.asset,
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.amount
                                )}{' '}
                                and your available{' '}
                                {
                                    assets.getAssetByIdentifier(
                                        custodyAssetNetworkInformation
                                            ?.estimatedFee?.asset
                                    )?.name
                                }{' '}
                                balance is{' '}
                                {assets.getPriceFormattedI(
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.asset,
                                    custodyAssetNetworkInformation
                                        ?.availableAmountInFeeAsset?.amount
                                )}
                            </Paragraph>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

const Paragraph = styled.p`
    opacity: 0.9;
    font-size: 0.8rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
`;

export default AccountInfo;
