import Image from 'components/atoms/Image';
import Loading from 'components/atoms/Loading';
import {SpinnerSizeEnum} from 'components/atoms/Loading/Loading';
import React from 'react';
import {useParams} from 'react-router-dom';
import {AssetDto} from 'state/store/api';
import styled from 'styled-components';
import {CustodyMainText, CustodyStyledPrintableTable, CustodySubText} from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import {BalanceYearMonth} from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import {Percentage} from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import moment from 'moment';
import { isNegative } from 'utils/isNegative';

const CustodyStatementTokenSummary = () => {
  const {year, month} = useParams();
  const {balance} = useCustodyStatement({year, month});
  const {getAssetByIdentifier} = useAssets();
  const date = new Date(+year!, +month! - 1);
  const lastDay = moment(date).endOf('month');

  return (
    <div>
      <TokenSummaryTitle>Token Summary</TokenSummaryTitle>
      {balance.isFetching && <Loading size={SpinnerSizeEnum.LARGE} showText={false} />}
      {!balance.isFetching && (
        <CustodyStyledPrintableTable>
          <thead>
            <tr>
              <th colSpan={7}>Data as of {lastDay.format('MMM DD, yyyy')} 11:59 UTC</th>
            </tr>
            <tr>
              <th>Asset</th>
              <th>Network</th>
              <th>Market Value</th>
              <th>Cost Basis</th>
              <th title='* Any unrealized gains/losses prior to Abra deposit is not accounted for'>Unrealized Gain / Loss*</th>
              <th>Period beginning Balance</th>
              <th>Period ending Balance</th>
            </tr>
          </thead>
          <tbody>
            {balance?.data?.map((record, index) => (
              <TokenSummaryRow key={index} record={record} asset={getAssetByIdentifier(record.balanceCurrency)} />
            ))}
          </tbody>
        </CustodyStyledPrintableTable>
      )}
    </div>
  );
};

interface TokenSummaryRowProps {
  asset?: AssetDto;
  record: BalanceYearMonth;
}
const TokenSummaryRow: React.FC<TokenSummaryRowProps> = ({asset, record}) => {
  const {getPriceFormattedI} = useAssets();
  const unrealizedAmount = record.unrealizedAmount ? getUsDollar(record.unrealizedAmount?.amount) : '-';
  const unrealizedPercentage = new Percentage(record.unrealizedPercentage)?.print();
  return (
    <tr>
      <td>
        <TokenImageBox>
          {asset && (
            <>
              <ImageBox>
                <Image width='100%' height='100%' src={asset?.icon} />
              </ImageBox>
              <TextGroup>
                <CustodyMainText>{asset?.name}</CustodyMainText>
              </TextGroup>
            </>
          )}
          {!asset && (
            <>
              <TextGroup>
                <CustodyMainText>{record?.endingBalance?.asset}</CustodyMainText>
              </TextGroup>
            </>
          )}
        </TokenImageBox>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{record.network}</CustodyMainText>
        </TextGroup>
      </td>

      <td>
        <CustodyMainText>{getUsDollar(record.endingPrice?.rate)}</CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.costBasis.amount)}>{getPriceFormattedI(record.costBasis.asset, record.costBasis.amount)}</CustodyMainText>
        </TextGroup>
      </td>

      <td>
        <CustodyMainText isNegative={isNegative(record.unrealizedAmount.amount)}>
          {unrealizedAmount} 
          <CustodySubText isNegative={isNegative(record.unrealizedAmount.amount)}>
            ({unrealizedPercentage})
          </CustodySubText>
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.startingValue.amount)}>{getUsDollar(record.startingValue.amount)}</CustodyMainText>
          <CustodySubText isNegative={isNegative(record.startingBalance.amount)}> {getPriceFormattedI(record.startingBalance.asset, record.startingBalance.amount)}</CustodySubText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.endingValue.amount)}>{getUsDollar(record.endingValue.amount)}</CustodyMainText>
          <CustodySubText isNegative={isNegative(record.endingBalance.amount)}>{getPriceFormattedI(record.endingBalance.asset, record.endingBalance.amount)}</CustodySubText>
        </TextGroup>
      </td>
    </tr>
  );
};

const ImageBox = styled.div`
  max-width: calc(min(4vw, 25px));
  min-width: calc(min(4vw, 25px));
`;
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 4vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;
export default CustodyStatementTokenSummary;
