import { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

let captcha_value = '';
let captcha_number = '';
let backgroundColor_value = '';
let fontColor_value = '';
let charMap_value = '';
let LoadCanvasTemplate_HTML = "<div><canvas id=\"canv\"></canvas><div><a id=\"reload_href\"  style=\"cursor: pointer; color: blue\">Reload Captcha</a></div></div>";
let LoadCanvasTemplateNoReload_HTML = "<div><canvas id=\"canv\"></canvas><div><a id=\"reload_href\"  style=\"cursor: pointer; color: blue\"></a></div></div>";;


export const loadCaptchaEngine = (length: number, backgroundColor = 'white', fontColor = 'black') => {

    backgroundColor_value = backgroundColor;
    fontColor_value = fontColor;
    let retVal = "";
    let charset = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789";

    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let captcha = retVal;

    captcha_value = captcha;



    let length_height_canvas = Math.round(length / 3);

    let canvas: any = document.getElementById('canv'),
    ctx = canvas?.getContext('2d'),
    img = document.getElementById('image');
    let text = captcha;
    let x = 12.5;
    let y = 15;
    let lineheight = 30;

    let canvas_height = (length - length_height_canvas) * 20;
    let lines = text.split('\n');
    let lineLengthOrder = lines.slice(0).sort(function (a, b) {
        return b.length - a.length;
    });
    ctx.canvas.width = length * 25;
    ctx.canvas.height = (lines.length * lineheight);

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    ctx.textBaseline = "middle";
    ctx.font = "20px monospace";
    ctx.fillStyle = fontColor;




    let num = 0;
    for (let i = 0; i < length; i++) {
        num = num + 1;
        let heigt_num = 20 * num;
        ctx.fillText(retVal[i], heigt_num, Math.round(Math.random() * (15 - 12) + 12));
    }

    document.getElementById("reload_href")!.onclick = function () {
        loadCaptchaEngine(length, backgroundColor, fontColor);
    }

};

export const validateCaptcha = (userValue: string, reload = true) => {
    if (userValue != captcha_value) {
        if (reload == true) {
            loadCaptchaEngine(length, backgroundColor_value, fontColor_value);
        }

        return false;
    }

    else {
        return true;
    }
};

export class LoadCanvasTemplate extends Component {

    render() {
        let reload_text = "";
        let reload_color = "";
        LoadCanvasTemplate_HTML = "<div><canvas id=\"canv\" style=\"background-color: blue;\"></canvas><div><a id=\"reload_href\"  style=\"cursor: pointer; color: blue\">Reload Captcha</a></div></div>";
        reload_text = "Reload Captcha";
        reload_color = "blue";
        LoadCanvasTemplate_HTML = "<div><canvas id=\"canv\"></canvas><div><a id=\"reload_href\"  style=\"cursor: pointer; color: " + reload_color + "\">" + reload_text + "</a></div></div>";

        return (ReactHtmlParser(LoadCanvasTemplate_HTML));
    }

};

export class LoadCanvasTemplateNoReload extends Component {

    render() {
        return (ReactHtmlParser(LoadCanvasTemplateNoReload_HTML));
    }

};
