import styled from "styled-components";

const FormInputBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`;

export default FormInputBox
