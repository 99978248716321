import { palette } from "lib/theme";
import Loading from "../Loading"
import styled from "styled-components";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import React from "react";

interface ReviewButtonStyledProps {
    disabled: boolean;
}  

const ReviewButtonStyled = styled.button<ReviewButtonStyledProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  background-color: ${({ disabled }) => (disabled ? `${palette.gray.second}` : `${palette.accent.main}`)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: ${palette.white.main};
  font-size: 0.7rem;
  opacity: 0.9;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 1.1rem;
  padding: 6px 18px;
  min-width: 98px;
  min-height: 37px;
  justify-content: center;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? `${palette.gray.second}` : `${palette.accent.light80}`)};
  }
`;

interface ReviewButtonProps {
    handleOnClick: () =>(void | Promise<void>)
    isDisabled: boolean
    text: string
    isLoading?: boolean
}

const ReviewButton = ({text, isDisabled, isLoading = false, handleOnClick}: ReviewButtonProps) => {
    return (
        <ReviewButtonStyled
            onClick={() => handleOnClick()}
            disabled={isDisabled}
        >
            {isLoading ? 
                <Loading 
                    showRoundSpinner 
                    size={SpinnerSizeEnum.BUTTON} 
                    showText={false} 
                    spinnerColor={palette.white.main}
                /> : 
                text
            }
        </ReviewButtonStyled>
    )

}

export default ReviewButton