import React, { FC } from 'react';
import ISVG from '@types/icons/svg';

export const Layers: FC<ISVG> = ({
    size = 24,
    width = size,
    height = size,
    color = 'white',
    svgProps,
}) => (
    <svg width={width} height={height} viewBox="0 0 430 430" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path d="M80.5005 238L33.9004 260.788L215 344.838L396.1 260.788L349.5 239" stroke={color} strokeWidth={size} strokeLinecap='round' strokeLinejoin='round'  />
        <path d="M80.5005 193.262L33.9004 216.05L215 300.1L396.1 216.05L349.5 194.262" stroke={color} strokeWidth={size} strokeLinecap='round' strokeLinejoin='round' />
        <path d="M215 256.1L33.9004 172.05L215 88L396.1 172.05L215 256.1Z" stroke={color} strokeWidth={size} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default Layers;
