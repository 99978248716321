import { Percentage } from 'lib/utils/types'
import React from 'react'
import { useParams } from 'react-router-dom'
import getUsDollar from 'shared/dollarFormat'
import { DefiBalanceStatementRow, useGetStatementDefiBalancesQuery } from 'state/store/statementsApi'
import styled from 'styled-components'
import { CustodyMainText, CustodyStyledPrintableTable, CustodySubText } from '../CustodyStyles'

import InkindAssetRenderer from "../../../atoms/asset/InkindAssetRenderer"
import { isNegative } from 'utils/isNegative'
import { palette } from 'lib/theme'

const CustodyStatementInvestmentSummary = () => {

  const { month, year } = useParams();
  const yearMonth = {year, month: month?.padStart(2, '0')}
  const statementDefiBalance = useGetStatementDefiBalancesQuery(yearMonth)
  
  const data = statementDefiBalance.data ?? []
  return (
  <div>
    <TokenSummaryTitle>Investment Summary</TokenSummaryTitle>
    <CustodyStyledPrintableTable>
      <thead>
        <tr>
          <th>Strategy Name</th>
          <th>Period Ending Balance</th>
          <th>Income (YTD)</th>
          <th>Cost Basis</th>
          <th>Unrealized Gain / Loss*</th>
          <th>Realized Gain / Loss (YTD)</th>
          <th>Period beginning balance</th>
          <th>Period ending balance</th>
        </tr>
      </thead>
      <tbody>
        {data.map((record, index) => <CustodySummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledPrintableTable>
  </div>
  )
}

interface CustodySummaryRowProps {
  record: DefiBalanceStatementRow
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  return (
    <tr>
      <td>
        <CustodyMainText>
          {record.strategyName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
            <InkindAssetRenderer
                balance={record.endingBalance}
                value={record.endingValue}
            />
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
            <InkindAssetRenderer
                balance={record.yieldYTD} 
                value={record.incomeYTD}
            />
        </CustodyMainText>
      </td>
      <td>
      <CustodyMainText>{getUsDollar(record.costBasis?.amount ?? '0')}</CustodyMainText>
      </td>
      
      <td>
        <GainLossArea 
          isNegative={isNegative(record.unrealizedAmount?.amount)}
        >
          {getUsDollar(record.unrealizedAmount?.amount ?? '0')}
          <CustodySubText isNegative={isNegative(record.unrealizedAmount?.amount)}>
            ({new Percentage(record.unrealizedPercentage).print()})
          </CustodySubText> 
        </GainLossArea>
      </td>
        <td>
            <CustodyMainText>
                <GainLossArea 
                  isNegative={isNegative(record.realizedYTD?.amount)}
                >
                    {getUsDollar(record.realizedYTD?.amount ?? '0')} 
                </GainLossArea>
            </CustodyMainText>
        </td>
        <td>
            <CustodyMainText>
                <InkindAssetRenderer
                    balance={record.startingBalance}
                    value={record.startingValue}
                />
            </CustodyMainText>
        </td>
        <td>
            <CustodyMainText>
                <InkindAssetRenderer
                    balance={record.endingBalance}
                    value={record.endingValue}
                />
            </CustodyMainText>
        </td>
    </tr>
  )
}

interface GainLossProps {
  isNegative: boolean
}
const GainLossArea = styled.div<GainLossProps>`
  color: ${({isNegative}) => isNegative ? palette.red.main : palette.white.light80};
  display: flex;
  flex-direction: column;
`

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`
export default CustodyStatementInvestmentSummary
