import { PagesUrl } from 'lib/constants/config.constant'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'shared/usePermissions'

const useCustodyRedirect = () => {
  const { isAccountManager, products, username } = usePermissions()
  const navigate = useNavigate();
  
  const canAccessCustody = isAccountManager || (!!products?.find(p => "Custody" === p));

  useEffect(() => {
    if(username) {
      if(!canAccessCustody){
        const goToBoost = products?.includes("Boost")
        if(goToBoost){
          navigate(PagesUrl.BOOST)
        } else {
          navigate(PagesUrl.BORROW)
        }
      }
    }
  }, [isAccountManager, products, username, canAccessCustody, navigate])

  return { products }
}

export default useCustodyRedirect
