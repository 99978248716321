import { selectCurrentUser } from './../../../state/slice/auth.slice';
import { useSelector } from 'react-redux';
import useAssets from 'shared/useAssets';
import useBalance from './extraCustodyHooks/useBalance'
import useTransactions from './extraCustodyHooks/useTransactions'

const useCustody = () => {
  const { balance, isLoadingBalance } = useBalance()
  const user = useSelector(selectCurrentUser)
  const smaAccountNumber = user?.organization?.smaAccountNumber
  const transactions = useTransactions()
  const { getPrice, getAsset, getAssetByIdentifier } = useAssets()

  const errorRetrievingBalance = !!balance.filter(a => !a.availableValue?.amount).length

  const availableAmount = balance
    .map((record) => record.availableValue?.amount)
    .map(value => value ? +value : 0)
    .reduce((total, next) => total + next, 0)

  const totalAmount = balance
    .map((record) => record.currentValue?.amount)
    .map(value => value ? +value : 0)
    .reduce((total, next) => total + next, 0)

  return {
    user,
    balance,
    availableAmount,
    totalAmount,
    getPrice,
    smaAccountNumber, 
    getAsset,
    getAssetByIdentifier,
    errorRetrievingBalance, 
    isLoadingBalance,
    ...transactions
  }
}

export default useCustody
