import bigDecimal from 'js-big-decimal';
import { TransferAddress } from 'state/store/withdrawApi';

export function num(numStr: string): bigDecimal {
    numStr = numStr.trim();

    if (numStr === '') {
        return new bigDecimal('0');
    }

    try {
        return new bigDecimal(numStr);
    } catch (error) {
        return new bigDecimal('0');
    }
}

interface AddressCategories {
    verified: TransferAddress[];
    pendingConfirmFund: TransferAddress[];
    pending: TransferAddress[];
    error: TransferAddress[];
}

export function categorizeAddresses(
    addresses: TransferAddress[]
): AddressCategories {
    return addresses.reduce(
        (acc: AddressCategories, address) => {
            if (address.verified) {
                acc.verified.push(address);
            } else if (address.usedWithdrawCompleted) {
                acc.pendingConfirmFund.push(address);
            } else if (
                !address.usedWithdrawCompleted &&
                address.usedWithdrawRequest &&
                !address.usedWithdrawError
            ) {
                acc.pending.push(address);
            } else {
                acc.error.push(address);
            }
            return acc;
        },
        { verified: [], pendingConfirmFund: [], pending: [], error: [] }
    );
}

