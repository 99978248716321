import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { MarginContainer } from '../Custody';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import Widget from 'components/templates/Widget';
import Image from 'components/atoms/Image';

// Import images
import USDCImage from '/public/assets/png/USDC_Logo.png';
import BNBImage from '/public/assets/png/BNB_Logo.png';
import BTCImage from '/public/assets/png/BTC_Logo.png';
import ETHImage from '/public/assets/png/ETH_Logo.png';
import SolanaImage from '/public/assets/png/Solana_Logo.png';
import TetherImage from '/public/assets/png/Tether_Logo.png';

const theme = {
    colors: {
        primary: '#3e146f',
        secondary: '#3e146fbf',
        text: '#ffffff',
    }
};

interface CryptoCardData {
    image: string;
    name: string;
}

const CryptoCard: React.FC<CryptoCardData> = React.memo(({ image, name }) => (
    <StyledCryptoCard>
        <Image width={42} height={42} src={image} alt={`${name} logo`} />
        <span>{name}</span>
    </StyledCryptoCard>
));

const TradeMainPage: React.FC = () => {
    useCustodyRedirect();

    const cryptoData: CryptoCardData[] = [
        { image: BTCImage, name: "Bitcoin" },
        { image: ETHImage, name: "Ethereum" },
        { image: TetherImage, name: "Tether" },
        { image: BNBImage, name: "BNB" },
        { image: SolanaImage, name: "Solana" },
        { image: USDCImage, name: "USDC" },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <MarginContainer>
                    <Widget>
                        <Container>
                            <InfoSection>
                                <Title>Trade with Abra</Title>
                                <Description>
                                    Buy and sell 100+ digital assets with deep liquidity and low 
                                    slippage on a secured and trusted platform including both crypto/fiat 
                                    and crypto/crypto pairs.
                                </Description>
                                <ContactButton>
                                    Contact{' '}
                                    <StyledLink
                                        href='mailto:globalsales@abra.com'
                                        aria-label='Email global sales'
                                    >
                                        globalsales@abra.com
                                    </StyledLink>
                                </ContactButton>
                            </InfoSection>
                            <CryptoGrid>
                                {cryptoData.map((crypto, index) => (
                                    <CryptoCard key={index} {...crypto} />
                                ))}
                            </CryptoGrid>
                        </Container>
                    </Widget>
                </MarginContainer>
            </Layout>
        </ThemeProvider>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
    max-width: 1200px;
`;

const InfoSection = styled.div`
    flex: 1;
`;

const Title = styled.h1`
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text};
`;

const Description = styled.p`
    margin-bottom: 2rem;
    max-width: 600px;
    color: ${props => props.theme.colors.text};
    line-height: 2;
`;

const ContactButton = styled.div`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.2rem;
`;

const StyledLink = styled.a`
    color: ${props => props.theme.colors.text};
    font-weight: bold;
    text-decoration: underline;
`;

const CryptoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    flex: 1;
`;

const StyledCryptoCard = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: ${props => props.theme.colors.text};
`;

export default TradeMainPage;