import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { toast, ToastType } from "components/organisms/Toast";
import { ErrorCodes } from "constant/constant";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      action?.payload?.status === 401 ||
      action?.payload?.status === "PARSING_ERROR"
    )
      return next(action);

    if (isRejectedWithValue(action)) {
      const isSupported = Object.keys(ErrorCodes).includes(
        action?.payload?.data?.code?.toString()
      );

      if (action?.payload?.status === 403 || isSupported) {
      } else if (action?.payload?.status === 500) {
        toast.show({
          title: "An error has occurred",
          content: "Please try again later or contact your account manager.",
          type: ToastType.Fail,
          duration: 5000
        });
      } else {
        toast.show({
          title: "An error has occurred",
          content:
            action?.payload?.data?.message ||
            "Please try again later or contact your account manager.",
          type: ToastType.Fail,
        });
      }
    }

    return next(action);
  };
