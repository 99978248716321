import React from 'react';
import useAssets from 'shared/useAssets';
import styled from 'styled-components';
import getUsDollar from "../../../shared/dollarFormat";
import { CustodyMainText, CustodySubText } from '../../pages/custody/CustodyStyles';
import Tooltip from '../Tooltip';
import { isNegative } from 'utils/isNegative';
import { palette } from 'lib/theme';

interface Props {
    balance: {
        asset: string;
        amount: string;
    },  
    value?: {
        asset: string;
        amount: string;
    },
}

const InkindAssetRenderer = (props: Props) => {
    const {getAssetByIdentifier, getPriceFormattedShort, getPriceFormattedI} = useAssets();

    if (!props.balance?.asset || props.balance?.asset === 'undefined') {
      return <>--</>
    }
    
    const humanReadableBalanceAsset = getAssetByIdentifier(props.balance.asset);
    const renderedInDollars = getUsDollar(props.value?.amount ?? '0');
    const fullAmount = getPriceFormattedI(props.balance.asset, props.balance.amount)
    const shortAmount = getPriceFormattedShort(props.balance.asset, props.balance.amount)
    return <>
      <Tooltip text={fullAmount}>
        <FlexContainer>
          <CustodyMainText isNegative={isNegative(props.balance.amount)}>
            <TotalTokenQuantity>{shortAmount}</TotalTokenQuantity>
          </CustodyMainText>
          <HumanReadable isNegative={isNegative(props.balance.amount)}>
            {humanReadableBalanceAsset?.name}&nbsp;
          </HumanReadable>
        </FlexContainer>
      </Tooltip>
        {props.value ? <CustodySubText isNegative={isNegative(props.value?.amount)}>({renderedInDollars})</CustodySubText> : null}
    </>;  
}

const FlexContainer = styled.div`
  display: flex;
  cursor: help;
  align-items: center;
  gap: 0.5ch;
`;

const TotalTokenQuantity = styled.span`
  display: inline-block;
  max-width: 10ch;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
`;

interface HumanReadableProps {
  isNegative: boolean;
}

const HumanReadable = styled.span<HumanReadableProps>`
  color: ${({isNegative}) => isNegative ? palette.red.main : palette.white.light80};
  @media print {
    color: ${palette.black.main}
  }
`



export default InkindAssetRenderer