import React, { FC } from 'react';
import { capitalize } from 'shared';
import useAssets from 'shared/useAssets';
import { NetworkCustodyAndBoost } from 'state/slice/networksReducer'
import { AssetNetworkInformation } from 'state/store/custodyApi'
import styled from 'styled-components';

interface NetworkCardProps {
  network: NetworkCustodyAndBoost;
  max?: number;
  min?: number
  info?: AssetNetworkInformation
}
const NetworkCard: FC<NetworkCardProps> = ({ network, max, min, info }) => {
  const assets = useAssets()
  return (
    <NetworkDetailsBox>
      <ParagraphBox>
        <p>
          <strong>Network:</strong> <span>{network.description}</span>
        </p>
        {network.networkStatus && (
          <p>
            <strong>{capitalize(network?.networkStatus)}</strong>
          </p>
          )}
      </ParagraphBox>
      {!!network.withdrawalFee && (
        <div>
          <strong>Network Fee:</strong> <span>{network.withdrawalFee}</span>
        </div>
        )}
      {info && (
      <div>
        <strong>Estimated Network Fee:</strong> <span>{assets.getPriceFormattedI(info.estimatedFee.asset, info.estimatedFee.amount)}</span>
      </div>
      )}
      {network.withdrawalMax && (
        <div>
          <strong>Maximum Withdrawal Amount:</strong> <span>{max ? max : network.withdrawalMax}</span>
        </div>
        )}
      {network.withdrawalMin && (
        <div>
          <strong>Minimum Withdrawal Amount:</strong> <span>{min ? min : network.withdrawalMin}</span>
        </div>
        )}
      <div>
      </div>
    </NetworkDetailsBox>
  );
};

const ParagraphBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NetworkDetailsBox = styled.div`
  margin-top: 0.5vh;
  font-size: 0.6rem;
  padding: 1vh 1vw;
  border-radius: 12px;
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  flex-direction: column;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.2);
  gap: 0.5vh;
`;

export default NetworkCard;
