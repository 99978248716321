import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { MarginContainer } from '../Custody';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import Widget from 'components/templates/Widget';
import Image from 'components/atoms/Image';

// Import images
import Borrow from '/public/assets/png/Borrow_Private.png';
import DigitalAsset from '/public/assets/png/Digital_Asset_Private.png';
import Focus from '/public/assets/png/Focus_Private.png';
import Private from '/public/assets/png/Private_Private.png';
import BorrowPortfolio from 'pages/DefiBorrow/BorrowPortfolio'
import { palette } from 'lib/theme'
import { useGetMilestonesQuery } from 'state/store/custodyApi'

const theme = {
    colors: {
        primary: '#4f1a8d',
        secondary: '#3e146f80',
        text: '#ffffff'
    }
};




const LoanMainPage: React.FC = () => {
    useCustodyRedirect();
    

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <MarginContainer>
                  <BorrowPortfolio />
                </MarginContainer>
            </Layout>
        </ThemeProvider>
    );
};

const ModalLink = styled.a`
  color: ${palette.accent.light};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.51px;
`;

const StyledLink = styled.a`
    color: ${props => props.theme.colors.text};
    font-weight: bold;
    text-decoration: underline;
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 50px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 1rem;
    width: 100%; 
    max-width: 300px;
    margin: 0 auto;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1200px; 
    margin: 0 auto; 
`;

const CardTitle = styled.h2`
    font-size: 1.85rem;
    font-weight: 500;
    margin: 0;
`;

const Title = styled.h1`
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text};
`;

const ContactButton = styled.div`
    margin-top: 25px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: block; 
    margin-bottom: 2rem;
    font-size: 1.2rem;
    width: 100%; 
    box-sizing: border-box; 
    text-align: center;
    max-width: 560px;
`;

const CryptoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: stretch;
    width: 100%;
`;

const CryptoCard = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
`;

export default LoanMainPage;
