import React from 'react';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import Modal from 'components/organisms/Modal';
import DigitVerification from 'components/atoms/DigitVerification';
import { TypesNamesEnum } from 'enums/Button.enum';
import PrimeTerms from 'assets/abra-prime.docx';
import styled from 'styled-components';

interface WithdrawModalProps {
    visible: boolean;
    withdrawStep: 'terms' | 'password' | 'otp' | 'email' | 'confirm';
    termsAccepted: boolean;
    password: string;
    otpCode: string[];
    emailCode: string[];
    selectedAsset: any;
    selectedNetwork: any;
    address: any;
    assets: any;
    custodyAssetNetworkInformation: any;
    loading: boolean;
    onCancel: () => void;
    onProceed: () => void;
    onTermsAccept: () => void;
    onPasswordChange: (value: string) => void;
    onOtpChange: (value: string[]) => void;
    onEmailChange: (value: string[]) => void;
    onEmailPaste: () => void;
    generateAmountDeducted: () => string;
    displayBalancesToDeduct: () => React.ReactNode;
    generateAmountSent: () => string;
    currentStepIsValid: () => boolean | string;
    stepsModalHeader: () => string;
    setOtpCode: (value: React.SetStateAction<string[]>) => void;
    setEmailCode: (value: React.SetStateAction<string[]>) => void;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
    visible,
    withdrawStep,
    termsAccepted,
    password,
    otpCode,
    emailCode,
    selectedAsset,
    selectedNetwork,
    address,
    assets,
    custodyAssetNetworkInformation,
    loading,
    onCancel,
    onProceed,
    onTermsAccept,
    onPasswordChange,
    onOtpChange,
    onEmailChange,
    onEmailPaste,
    generateAmountDeducted,
    displayBalancesToDeduct,
    generateAmountSent,
    currentStepIsValid,
    stepsModalHeader,
    setOtpCode,
    setEmailCode
}) => {
  
    const keyDownOtp = (event: { keyCode: number }, indx: number) => {
        if (event.keyCode === 8) {
            if (otpCode[indx]) {
                const updatedCureentValue = otpCode.slice(0, indx);
                setOtpCode(updatedCureentValue);
                return;
            }
            const updatedValue = otpCode.slice(0, indx - 1);
            setOtpCode(updatedValue);
        }
    };

    const keyDownEmail = (event: { keyCode: number }, indx: number) => {
      if (event.keyCode === 8) {
        if (emailCode[indx]) {
          const updatedCureentValue = emailCode.slice(0, indx);
          setEmailCode(updatedCureentValue);
          return;
        }
        const updatedValue = emailCode.slice(0, indx - 1);
        setOtpCode(updatedValue);
      }
    };

    const renderStepContent = () => {
        switch (withdrawStep) {
            case 'terms':
                return (
                    <TermsStep
                        termsAccepted={termsAccepted}
                        onTermsAccept={onTermsAccept}
                    />
                );
            case 'password':
                return (
                    <PasswordStep
                        password={password}
                        onPasswordChange={onPasswordChange}
                    />
                );
            case 'otp':
                return <OtpStep otpCode={otpCode} onOtpChange={onOtpChange} onKeyDown={keyDownOtp} />;
            case 'email':
                return (
                    <EmailStep
                        emailCode={emailCode}
                        onEmailChange={onEmailChange}
                        onEmailPaste={onEmailPaste}
                        onKeyDown={keyDownEmail}
                    />
                );
            case 'confirm':
                return (
                    <ConfirmStep
                        selectedAsset={selectedAsset}
                        selectedNetwork={selectedNetwork}
                        address={address}
                        assets={assets}
                        custodyAssetNetworkInformation={
                            custodyAssetNetworkInformation
                        }
                        generateAmountDeducted={generateAmountDeducted}
                        displayBalancesToDeduct={displayBalancesToDeduct}
                        generateAmountSent={generateAmountSent}
                    />
                );
            default:
                return null;
        }
    };

    const getLabel = (withdrawStep: string): string => {
        switch (withdrawStep) {
            case 'confirm':
                return 'Confirm';
            case 'terms':
                return 'Accept';
            default:
                return 'Next';
        }
    };

    return (
        <Modal visible={visible} header={stepsModalHeader()}>
            <Modal.Body>{renderStepContent()}</Modal.Body>
            <Modal.Footer>
                <Button
                    height='44'
                    buttonType={TypesNamesEnum.SECONDAY}
                    label='Cancel'
                    onClick={onCancel}
                />
                <Button
                    height='44'
                    buttonType={TypesNamesEnum.ACCENT}
                    label={getLabel(withdrawStep)}
                    onClick={onProceed}
                    disabled={!currentStepIsValid()}
                    isLoading={loading}
                />
            </Modal.Footer>
        </Modal>
    );
};

const TermsStep: React.FC<{
    termsAccepted: boolean;
    onTermsAccept: () => void;
}> = ({ termsAccepted, onTermsAccept }) => (
    <NormalSpacingBox>
        <ParagraphModal>
            Please confirm the withdrawal address, otherwise you may lose your
            funds.
        </ParagraphModal>
        <ParagraphModal>
            Before proceeding, you must review and accept the
            <ExternalLink
                href={PrimeTerms}
                download='Abra Prime User Agreement'
                target='_blank'
            >
                {' '}
                Abra Prime User Agreement{' '}
            </ExternalLink>{' '}
        </ParagraphModal>
        <Checkbox
            text='I agree to the terms of the Abra Prime User Agreement'
            checked={termsAccepted}
            onChange={onTermsAccept}
        />
    </NormalSpacingBox>
);

const PasswordStep: React.FC<{
    password: string;
    onPasswordChange: (value: string) => void;
}> = ({ password, onPasswordChange }) => (
    <NormalSpacingBox>
        <ParagraphModal>
            To proceed with your withdraw request, please confirm your password.
        </ParagraphModal>
        <br />
        <Input
            label='Your password here'
            inputProps={{
                value: password,
                type: 'password',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    onPasswordChange(e.target.value),
            }}
        />
    </NormalSpacingBox>
);

const OtpStep: React.FC<{
    otpCode: string[];
    onOtpChange: (value: string[]) => void;
    onKeyDown: (event: { keyCode: number }, indx: number) => void;
}> = ({ otpCode, onOtpChange, onKeyDown }) => (
    <NormalSpacingBox>
        <ParagraphModal>
            Please type the code provided from your authenticatorP app. This is
            the same application you used to login.
        </ParagraphModal>
        <DigitVerification
            code={otpCode}
            onChange={onOtpChange}
            onKeyDown={onKeyDown}
        />
    </NormalSpacingBox>
);

const EmailStep: React.FC<{
    emailCode: string[];
    onEmailChange: (value: string[]) => void;
    onEmailPaste: () => void;
    onKeyDown: (event: { keyCode: number }, indx: number) => void;
}> = ({ emailCode, onEmailChange, onEmailPaste, onKeyDown }) => (
    <NormalSpacingBox onPaste={onEmailPaste}>
        <ParagraphModal>
            Please check your email, you should have received an eight-digit
            verification code from Abra.
        </ParagraphModal>
        <DigitVerification
            code={emailCode}
            onChange={onEmailChange}
            onKeyDown={onKeyDown}
            digitsLength={8}
        />
    </NormalSpacingBox>
);

const ConfirmStep: React.FC<{
    selectedAsset: any;
    selectedNetwork: any;
    address: any;
    assets: any;
    custodyAssetNetworkInformation: any;
    generateAmountDeducted: () => string;
    displayBalancesToDeduct: () => React.ReactNode;
    generateAmountSent: () => string;
}> = ({
    selectedAsset,
    selectedNetwork,
    address,
    assets,
    custodyAssetNetworkInformation,
    generateAmountDeducted,
    displayBalancesToDeduct,
    generateAmountSent,
}) => (
    <>
        <ParagraphModalHeader>
            The amount of{' '}
            <strong>
                {generateAmountDeducted()} {selectedAsset?.name} + Actual
                Network Fee
            </strong>{' '}
            will be deducted from your balance of{' '}
            <strong>{displayBalancesToDeduct()}.</strong>
        </ParagraphModalHeader>
        <ParagraphModal>Transaction Preview</ParagraphModal>
        <SpacingBox>
            <LongParagraph>
                <strong>Network:</strong> {selectedNetwork?.name}
            </LongParagraph>
            <LongParagraph>
                <strong>Address:</strong> {address?.address}
            </LongParagraph>
            {address?.memo && (
                <LongParagraph>
                    <strong>Destination Tag / MEMO:</strong> {address.memo}
                </LongParagraph>
            )}
            <LongParagraph>
                <strong>Amount To Be Sent: </strong>{' '}
                {assets.getPriceFormatted(
                    selectedAsset?.name,
                    generateAmountSent()
                )}
            </LongParagraph>
            <LongParagraph>
                <strong>Estimated Network Fee:</strong>{' '}
                {assets.getPriceFormattedI(
                    custodyAssetNetworkInformation?.estimatedFee.asset,
                    custodyAssetNetworkInformation?.estimatedFee?.amount
                )}
            </LongParagraph>
        </SpacingBox>
        <br />
        <ParagraphModal>
            Once you confirm, we will submit the transaction to the network.
        </ParagraphModal>
    </>
);

const NormalSpacingBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.5vh;
    border-radius: 8px;
    padding: 4px 8px;
    gap: 4px;
`;

const ParagraphModal = styled.p`
    font-size: 0.8rem;
    opacity: 0.9;
`;

const LongParagraph = styled.p`
    font-size: 0.7rem;
    opacity: 0.8;
    word-break: break-all;
`;

const SpacingBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.5vh;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    padding: 4px 8px;
    gap: 4px;
`;

const ParagraphModalHeader = styled.p`
    font-size: 0.8rem;
    margin-top: 3vh;
    opacity: 0.9;
`;

const ExternalLink = styled.a`
    color: #a399f6;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 0.51px;
`;

export default WithdrawModal;
