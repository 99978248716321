import { useGetBorrowBalancesQuery } from 'state/store/custodyApi'

const useBorrow = () => {
  const borrowBalances = useGetBorrowBalancesQuery(null)
  return {
    borrowBalances
  }
}

export default useBorrow
