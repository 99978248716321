import React, { FC } from 'react';
import { Address } from 'services/models';
import { capitalize } from 'shared';
import useAssets from 'shared/useAssets';
import { NetworkCustodyAndBoost } from 'state/slice/networksReducer'
import { AssetNetworkInformation, CustodyNetwork } from 'state/store/custodyApi'
import styled from 'styled-components';

interface CustodyNetworkCardProps {
  network: NetworkCustodyAndBoost;
  info: AssetNetworkInformation;
  max: string;
  min: string;
}
const CustodyNetworkCard: FC<CustodyNetworkCardProps> = ({ network, max, info, min }) => {
  const assets = useAssets()
  return (
    <NetworkDetailsBox>
      <ParagraphBox>
        <p>
          <strong>Network:</strong> <span>{network.description}</span>
        </p>
      </ParagraphBox>
      <div>
        <strong>Estimated Network Fee:</strong> <span>{assets.getPriceFormattedI(info.estimatedFee.asset, info.estimatedFee.amount)} </span>
      </div>
      <div>
        <strong>Maximum Withdrawal Amount:</strong> <span>{assets.getPriceFormattedI(info.availableAmountInAsset.asset, max)}</span>
      </div>
      <div>
        <strong>Minimum Withdrawal Amount:</strong> <span>{assets.getPriceFormattedI(info.availableAmountInAsset.asset, min)}</span>
      </div>
    </NetworkDetailsBox>
  );
};

const ParagraphBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NetworkDetailsBox = styled.div`
  margin-top: 0.5vh;
  font-size: 0.6rem;
  padding: 1vh 1vw;
  border-radius: 12px;
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  flex-direction: column;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.2);
  gap: 0.5vh;
`;

export default CustodyNetworkCard;
