import React from 'react';
import styled from 'styled-components';
import { toast, ToastType } from 'components/organisms/Toast';
import { Copy } from 'assets/icons';

interface StepProps {
    number: number;
    children: React.ReactNode;
}

interface BankInfoData {
    recipientName: string;
    routingNumber: string;
    bankName: string;
    accountNumber: string;
    bankAddress: string;
    recipientAddress: string;
}

interface BankInfoTableProps {
    data: BankInfoData;
}

const copyText = (text: string) => {
    try {
        navigator.clipboard.writeText(text);
        toast.show({
            title: 'Text copied',
            content: 'Value was copied to the clipboard',
            duration: 5000,
            type: ToastType.Info,
        });
    } catch (e) {}
};

const BankInfoTable: React.FC<BankInfoTableProps> = React.memo(({ data }) => (
    <Table>
        <tbody>
            {Object.entries(data).map(([key, value]) => (
                <tr key={key}>
                    <th>{formatKey(key)}</th>
                    <CopyText onClick={() => copyText(value)}>
                        {value}
                        <Copy />
                    </CopyText>
                </tr>
            ))}
        </tbody>
    </Table>
));

const CopyText = styled.td`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    cursor: pointer;
`;

const formatKey = (key: string): string => {
    return key
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const Step: React.FC<StepProps> = React.memo(({ number, children }) => (
    <StepContainer>
        <StepNumber>{number}</StepNumber>
        <TextContainer>{children}</TextContainer>
    </StepContainer>
));

const Table = styled.table`
    width: calc(100% - 3rem);
    border-collapse: collapse;
    margin-top: 10px;
    margin-left: 3rem;

    th,
    td {
        border: 1px solid #fff;
        padding: 8px;
        text-align: left;
        vertical-align: middle;
    }

    th {
        width: 30%;
        font-weight: bold;
    }
`;

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const StepNumber = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    color: #ffffff;
    font-weight: bold;
    border: 2px solid #ffffff;
    font-size: 16px;
    position: relative;
    margin-right: 1rem;
`;

const TextContainer = styled.div`
    margin-top: 5px;
`;

const InfoField = styled.div`
    background-color: rgba(51, 51, 51, 0.3);
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 4px;
    display: inline-block;
    max-width: calc(100% - 1rem);
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: 3rem;
`;

export { Step, BankInfoTable, InfoField, copyText, CopyText };
export type { BankInfoData };
