import Widget from 'components/templates/Widget'
import { PagesUrl } from 'lib/constants/config.constant'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'shared/usePermissions'
import styled from 'styled-components'
import UserOnboardingStatus from './UserOnboardingStatus'

interface CustodyNavigationWidgetI {
  action?: () => void
  textAction?: string
}

const CustodyNavigationWidget: React.FC<CustodyNavigationWidgetI> = ({children, action, textAction}) => {

  const navigate = useNavigate()
  const { canDepositCustody, canDepositFiatCustody } = usePermissions()

  const link = window.location.href
  const isDepositCrypto = link.indexOf('deposit') !== -1 && link.indexOf('deposit-fiat') === -1
  const isDepositFiat = link.indexOf('deposit-fiat') !== -1
  const isDocuments = link.indexOf('document') !== -1
  const isChildPage = isDepositCrypto || isDepositFiat || isDocuments
  
  return (
    <VerticalContainer>
    <UserOnboardingStatus />
    <AbsoluteContainer>
      <Widget>
      <div>
        {children}
      </div>
      <ButtonBackArea>
      {!!isChildPage &&
        <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
      }
      {!!action &&
        <BackButton onClick={action}>{textAction}</BackButton>
      }
      </ButtonBackArea>
      <ButtonsArea>
        {canDepositFiatCustody &&<ActionButton isActive={isDepositFiat} onClick={() => navigate("/custody/deposit-fiat")}>Deposit USD</ActionButton>}
        {canDepositCustody && <ActionButton isActive={isDepositCrypto} onClick={() => navigate("/custody/deposit")}>Deposit Crypto</ActionButton>}
        <ActionButton isActive={isDocuments} onClick={() => navigate(PagesUrl.CUSTODY + PagesUrl.DOCUMENT)}>Documents</ActionButton>
      </ButtonsArea>
    </Widget>
  </AbsoluteContainer>
  </VerticalContainer>
  )
}

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  `
const AbsoluteContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin-top: 30px;
  @media print{
    background-color: white !important;
    margin-top:-80px !important;
    position: absolute;
    height: auto;
  }
  
`
interface ActionButtonProps {
  isActive: boolean
}

const BackButton =  styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #292749;
  padding: 4px 8px;
  height: 30px;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #6F2ACD;
  }
`

const ActionButton =  styled.div<ActionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #292749;
  padding: 4px 8px;
  height: 30px;
  width: 120px;
  transition: background-color 0.3s ease-in;
  ${props => props.isActive ? 'background-color: #6F2ACDAA;' : ''}
  cursor: pointer;
  &:hover {
    background-color: #6F2ACD;
  }
`
const ButtonsArea = styled.div`
  display: flex;
  gap: 3px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  position: absolute;
  top: -30px;
  right: 0;
  @media print{
       display:none;
  }
`

const ButtonBackArea = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  position: absolute;
  top: -30px;
  left: 0;
  @media print{
       display:none;
  }
`
export default CustodyNavigationWidget
