import React from 'react';
import styled from 'styled-components';
import {CustodyMainText, CustodyStyledPrintableTable} from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import {useParams} from 'react-router-dom';
import {ExpenseSummary} from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import getUsDollar from 'shared/dollarFormat';

const CustodyStatementInvestmentExpenseSummary = () => {
  const {month, year} = useParams();
  const {expenseSummary} = useCustodyStatement({month, year});
  return (
    <div>
      <TokenSummaryTitle>
        Investment Expense Summary YTD<small>**</small>
      </TokenSummaryTitle>
      <CustodyStyledPrintableTable>
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
              <th>Cost Basis</th>
            </tr>
          </thead>
          <tbody>

          {expenseSummary.data && expenseSummary.data.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
      </CustodyStyledPrintableTable>
    </div>
  );
};

interface CustodySummaryRowProps {
  record: ExpenseSummary
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  const {getPriceFormattedI} = useAssets()
  return (
    <tr>
      <td>
        <CustodyMainText>
        {record.category}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getPriceFormattedI(record.amount.asset, record.amount?.amount ?? '0')}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
        {getUsDollar(record.costBasisAmount?.amount)}
        </CustodyMainText>
      </td>
    </tr>
  )
}

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;

export default CustodyStatementInvestmentExpenseSummary;
