import React, { useEffect, useState } from 'react';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useParams } from 'react-router-dom';
import Layout from 'components/templates/Layout';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import moment from 'moment';
import styled from 'styled-components';
import useCustodyStatement from './useCustodyStatement';
import CustodyStatementTokenSummary from './CustodyStatementTokenSummary';
import CustodyStatementInvestmentSummary from './CustodyStatementInvestmentSummary';
import CustodyStatementTransaction from './CustodyStatementTransaction';
import CustodyStatementGainLossDetails from './CustodyStatementGainLossDetails';
import CustodyStatementCostBasisWithdrawals from './CustodyStatementCostBasisWithdrawals';
import PrintIcon from 'assets/icons/PrintIcon';
import getUsDollar from 'shared/dollarFormat';
import bigDecimal from 'js-big-decimal';
import CustodyStatementInvestmentExpenseSummary from './CustodyStatementInvestmentExpenseSummary'
import { CustodyMainText } from '../CustodyStyles';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';
import { palette } from 'lib/theme';

const CustodyStatement: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { month, year } = useParams();
  const date = new Date(+year!, +month! - 1);
  const lastDay = moment(date).endOf('month');
  const firstDay = moment(date).startOf('month');
  const { smaAccountNumber, totalStatement, totalBalancePeriod: endingCustodyBalance, totalInvestmentBalance: endingInvestmentBalance, balance, beginningInvestmentBalance } = useCustodyStatement({ year, month });

  const beginningCustodyBalance = balance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.startingBalance.amount ?? 0)), new bigDecimal(0));

  const beginningBalance = new bigDecimal(beginningCustodyBalance?.getValue()).add(new bigDecimal(beginningInvestmentBalance)).getValue();
  const endingBalance = new bigDecimal(endingCustodyBalance).add(new bigDecimal(endingInvestmentBalance)).getValue();

  useEffect(() => {
    setIsLoading(
      balance.isLoading || 
      totalStatement.isLoading    
    )
  }, [balance.isLoading, totalStatement.isLoading])

  const print = () => {
    window.print();
    return false;
  };

  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer id='pdf-area'>
        <CustodyNavigationWidget>
          <TitlePage>
            Statement{' '}
            <PrintButton disabled={isLoading} onClick={print}>
              {isLoading ?
                  <Loading
                    showText={false}
                    size={SpinnerSizeEnum.INPUT}
                    showRoundSpinner
                    spinnerColor={palette.white.main}
                  /> :
                <PrintIcon />
              }
            </PrintButton>
          </TitlePage>
          <HorizontalFlexContainer>
            <BoxValues>
              <PairTitle>SMA Account Number</PairTitle>
              <PairValue>{smaAccountNumber}</PairValue>
            </BoxValues>
            <div></div>
            <div></div>
            <BoxValues>
              <PairTitle>Period</PairTitle>
              <PairValue>
                {firstDay.format('MMMM Do, YYYY')} - {lastDay.format('MMMM Do, YYYY')}
              </PairValue>
            </BoxValues>
          </HorizontalFlexContainer>
          <HorizontalFlexContainer>
            <BoxValues>
              <PairTitle>Total Beginning Balance</PairTitle>
              <PairValue>{getUsDollar(beginningBalance)}</PairValue>
            </BoxValues>
            <BoxValues>
              <PairTitle>Custody Beginning Balance</PairTitle>
              <PairValue>{getUsDollar(beginningCustodyBalance?.getValue())}</PairValue>
            </BoxValues>
            <BoxValues>
              <PairTitle style={{justifyContent: "flex-end"}}>Investment Beginning Balance</PairTitle>
              <PairValue style={{justifyContent: "flex-end"}}>{getUsDollar(beginningInvestmentBalance)}</PairValue>
            </BoxValues>
          </HorizontalFlexContainer>
          <HorizontalFlexContainer>
            <BoxValues>
              <PairTitle>Total Ending Balance</PairTitle>
              <PairValue>{getUsDollar(endingBalance)}</PairValue>
            </BoxValues>
            <BoxValues>
              <PairTitle>Custody Ending Balance</PairTitle>
              <PairValue>{getUsDollar(endingCustodyBalance)}</PairValue>
            </BoxValues>
            <BoxValues>
              <PairTitle style={{justifyContent: "flex-end"}}>Investment Ending Balance</PairTitle>
              <PairValue style={{justifyContent: "flex-end"}}>{getUsDollar(endingInvestmentBalance)}</PairValue>
            </BoxValues>
          </HorizontalFlexContainer>
          <HorizontalFlexContainer>
            <BoxValues>
              <PairTitle>Realized Gain/ Loss YTD</PairTitle>
              <PairValue>{getUsDollar(totalStatement.data?.realizedAmountYtd.amount)}</PairValue>
            </BoxValues>
            <BoxValues>
              <PairTitle>Income YTD</PairTitle>
              <PairValue>{getUsDollar(totalStatement.data?.incomeAmountYtd.amount)}</PairValue>
            </BoxValues>
          </HorizontalFlexContainer>
          <CustodyStatementTokenSummary />
          <CustodyStatementInvestmentSummary />
          <CustodyStatementInvestmentExpenseSummary />
          <CustodyStatementTransaction />
          <CustodyStatementGainLossDetails />
          <CustodyStatementCostBasisWithdrawals />
          <br></br>
          <br></br>
          <p id='foot-note1'>* Any unrealized gain/ loss prior to Abra deposit is not accounted for</p>
          <br></br>
          <p id='foot-note2'>** investment expenses do not increase the cost basis of the investment</p>
          <br></br>
          <p id='foot-note3'>*** This is calculated as the overall cost basis of the investment (closing amount - cost basis - total network fee = realized gain/ loss)</p>
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};

const BoxValues = styled.div``;

const PrintButton = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f2acd99;
  padding: 6px;
  border-radius: 50%;
  transition: all 1s;
  border: unset;
  &:hover {
    background-color: #6f2acd;
  }
`;


const HorizontalFlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const TitlePage = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;

const PairTitle = styled(CustodyMainText)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
`;

const PairValue = styled(CustodyMainText)`
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.48px;
  margin-bottom: 30px;
`;

export default CustodyStatement;
