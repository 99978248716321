import React, { FC, useEffect, useState } from "react"

import { Sizes } from "./Boost"
import { Heading, Text, Title } from "components/atoms/Typography"
import PanelWithHeader from "components/molecules/PanelWithHeader"
import styled from "styled-components"
import { BalancePieChart } from "pages/BalanceWidget"
import Percent from "components/atoms/Number/Percent"
import Currency from "components/atoms/Number/Currency"
import { palette } from "lib/theme"
import Row from "components/atoms/Row/Row"
import {
  AssetDto,
  OrdersSummaryResponse,
  useGetAssetsQuery,
} from "state/store/api"
import LoadingStage from "pages/LoadingStage"
import { formatNumberDisplay } from "shared"
import useAssetFormatHook from 'shared/useAssetFormatHook'
import { pricesSelector } from 'state/slice/prices.slice'
import { useSelector } from 'react-redux'

interface PortfolioProps extends Sizes {
  orders: OrdersSummaryResponse
  isLoading: boolean
  isFetching: boolean
}

const Portfolio: FC<PortfolioProps> = (props) => {
  const { orders, isLoading } = props
  const [legends, setLegends] = useState<{ title: string; detail: string }[]>(
    []
  )
  const [list, setList] = useState<PieChartItem[]>([])
  const [balance, setBalance] = useState<number>(0)

  const prices = useSelector(pricesSelector)
  const { data: assetsInfo, isLoading: assetsLoading } = useGetAssetsQuery()

  const assetFormat = useAssetFormatHook()
  useEffect(() => {
    if (!orders && !prices && !assetsInfo) return
    const currentBalance = !orders?.assets
      ? 0
      : orders.assets.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.asset
        )?.price
        const amount =
          parseFloat(current.amount ?? "0") * parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const initialPrincipalDepositValue = !orders?.assets
      ? 0
      : orders.assets.reduce((previous, current) => {
   
        const amount =
          parseFloat(current.initialPrincipalDepositValue ?? "0")
        return previous + amount
      }, 0)

    const interestAccruedYearToDate = !orders?.assets
      ? 0
      : orders.assets.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.asset
        )?.price
        const amount =
          parseFloat(current.interestAccruedYearToDate ?? "0") *
          parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const currentLegends = [
      {
        title: "initial principal deposit",
        detail: formatNumberDisplay(initialPrincipalDepositValue, "currency") ?? "-",
      },
      {
        title: "interest accrued",
        detail:
          formatNumberDisplay(interestAccruedYearToDate, "currency") ?? "-",
      },
      { title: "Open deposits", detail: orders?.openOrders?.toString() ?? "-" },
    ]

    const response = !orders?.assets
      ? []
      : orders.assets
        ?.map((item) => {
          const price = prices?.find(
            (m) => m.identifier === item.asset
          )?.price

          const asset = assetsInfo?.assets?.find(
            (m) => m.name?.toUpperCase() === item.asset?.toUpperCase()
          )

          return {
            name: asset?.longName ?? item.asset?.toUpperCase(),
            asset: asset,
            amount: assetFormat.getAssetFormatted(asset?.identifier, item.amount),
            currencyAmount:
              parseFloat(item.amount ?? "0") * parseFloat(price ?? "0"),
            percent:
              (parseFloat(item.amount ?? "0") * parseFloat(price ?? "1")) /
              currentBalance,
          }
        })
        .sort((m) => m.currencyAmount)

    setBalance(currentBalance)
    setLegends(currentLegends)
    setList(response)
  }, [orders, prices, assetsInfo, assetFormat.isLoading])

  return (
    <PanelWithHeader
      width="100%"
      minHeight="174px"
      title="Portfolio View"
      {...props}
    >
      {isLoading || assetsLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingStage loadingText=" " />
        </div>
      ) : (
        <>
          <Row direction="column" gap="5px">
            <Title>Current balance</Title>
            <Heading variant="h1">
              <Currency value={balance} />
            </Heading>
          </Row>
          {balance > 0 ? (
            <>
              <Row margin="24px 0px 43px 0px">
                <Details legends={legends} />
              </Row>
              <PieChartItems list={list} />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </PanelWithHeader>
  )
}

const Details: FC<{ legends: { title: string; detail: string }[] }> = ({
  legends,
}) => {
  return (
    <Row direction="row" gap="40px">
      {legends.map((m) => {
        return <Detail key={m.title} {...m} />
      })}
    </Row>
  )
}

const Detail: FC<{ title: string; detail: string }> = ({ title, detail }) => {
  return (
    <Row direction="column" gap="8px">
      <Title>{title}</Title>
      <Text size="large" letterSpacing="-0.48px">
        {detail}
      </Text>
    </Row>
  )
}

interface PieChartItem {
  asset?: AssetDto,
  name?: string
  amount: string
  currencyAmount: number
  percent: number
}
interface PieChartItemsProps {
  list: PieChartItem[]
}
const PieChartItems: FC<PieChartItemsProps> = ({ list }) => {
  const assetFormat = useAssetFormatHook()
  const values = list.map((item) => {
    return { value: assetFormat.getAssetFormatted(item.asset?.identifier, item.currencyAmount) }
  })

  return (
    <Row justifyContent="flex-start" gap="58px" marginTop="33px">
      <BalancePieChart data={values} />
      <Row
        direction="column"
        justifyContent="center"
        width="262.5px"
        height="282px"
        gap="11px"
      >
        {list.length ? (
          list.map(
            ({ name, amount, currencyAmount, percent }, index: number) => (
              <Row gap="12px" key={`${index}-coin`}>
                <Row
                  gap="0.50rem"
                  alignItems="center"
                  width="250px"
                  height="18px"
                >
                  <Dot color={palette.charts.balancePieChart[index]} />
                  <Text ellipsis size="small">
                    {` ${name}`}{" "}
                  </Text>
                  <Text size="small" color={palette.gray.main as string}>
                    {amount}
                  </Text>{" "}
                </Row>
                <Row gap="0.25rem" alignItems="center">
                  <Text size="small">
                    <Currency value={currencyAmount} />
                  </Text>
                  <Text
                    size="tiny"
                    color={palette.darkBackgroundContrast.light70}
                  >
                    (
                    <Percent
                      signDisplay="auto"
                      value={percent}
                    />
                    )
                  </Text>
                </Row>
              </Row>
            )
          )
        ) : (
          <></>
        )}
      </Row>
    </Row>
  )
}

const Dot = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || "none"};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex: 0 0 auto;
`

export default Portfolio
